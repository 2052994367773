import React from 'react';
import { Grid } from '@mui/material';
import TemplateDashboardSearchBar from '../../TemplateDashboardSearchBar';
import { SearchCategoryList } from '../SearchCategories/SearchCategoryList';
import { CATEGORIES } from '../../../../common/constants/categories';
import { useSelector } from 'react-redux';
import { selectAllCategories } from '../../../../slices/categories/categoriesSlice';
import { useTranslation } from 'react-i18next';
import { SearchCategoriesContainerProps } from './types';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';

export const SearchCategoriesContainer = ({ hideList }: SearchCategoriesContainerProps) => {
  const categories = useAppSelector(selectAllCategories);
  const { t } = useTranslation();
  const categoriesCopy = [...categories];
  const groupedCategories = () => {
    const groupedItems: { name: string; id: number; className: string }[] = [];
    for (const item of categoriesCopy) {
      if (item.parent === null) {
        const el = { name: item.name, id: item.id, className: 'level-1' };
        groupedItems.push(el);
        if (item.sub_categories && item.sub_categories.length > 0) {
          for (const cl2 of item.sub_categories) {
            const cl2Item = { name: cl2.name, id: cl2.id, className: 'level-2' };
            groupedItems.push(cl2Item);
            for (const cl3 of categories.filter((el) => el.parent === cl2.id)) {
              const cl3Item = { name: cl3.name, id: cl3.id, className: 'level-3' };
              groupedItems.push(cl3Item);
            }
          }
        }
      }
    }
    return groupedItems;
  };

  return (
    <>
      <Grid container item justifyContent='center' className='dashboard-search mb-0'>
        <TemplateDashboardSearchBar
          placeholder={t('messages.enterTemplateName')}
          filtered
          categories={groupedCategories()}
        />
      </Grid>
      {!hideList && <SearchCategoryList categories={CATEGORIES} />}
    </>
  );
};

import { useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

export const useSelectField = ({
  onChange,
  defaultValue,
}: {
  onChange: (value: string) => void;
  defaultValue?: string;
}): { handleChange: (event: SelectChangeEvent) => void; selectedOption: string } => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  useMemo(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value);
    onChange(event.target.value);
  };

  return { handleChange, selectedOption };
};

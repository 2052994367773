import React, { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { INVITE_STATUS } from '../../../../../common/constants/statuses';

interface InviteStatusProps {
  status: INVITE_STATUS;
  children: ReactElement;
}

export const InviteStatus = ({ status, children }: InviteStatusProps): ReactElement => {
  switch (status) {
    case INVITE_STATUS.LOADING:
      return (
        <div className='invite-status-container'>
          <CircularProgress size={20} />
        </div>
      );
    case INVITE_STATUS.COMPLETED:
      return (
        <div className='invite-status-container'>
          <CheckIcon />
        </div>
      );
    default:
      return children;
  }
};

import React from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import CustomerNotesList from '../../CustomerNotesList';

export const CustomerNotesTab = ({ currentTab }: TabProps) => {
  return (
    <TabPanel value={currentTab} index={2} prefix={'vertical'}>
      <>
        <CustomerNotesList />
      </>
    </TabPanel>
  );
};

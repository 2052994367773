import React from 'react';
import { Grid } from '@mui/material';
import { SettingsMenu } from './components/SettingsMenu';
import { PersonalDetailsTab } from './components/tabs/PersonalDetailsTab';
import { EmploymentDetailsTab } from './components/tabs/EmploymentDetailsTab';
import { EmergencyContactTab } from './components/tabs/EmergencyContactTab';
import { QualificationsTab } from './components/tabs/QualificationsTab';

const EmployeeProfileSettingsPage = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
        <SettingsMenu value={currentTab} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12} md={9}>
        <div className='setting-panel'>
          <PersonalDetailsTab currentTab={currentTab} />
          <EmploymentDetailsTab currentTab={currentTab} />
          <QualificationsTab currentTab={currentTab} />
          <EmergencyContactTab currentTab={currentTab} />
        </div>
      </Grid>
    </Grid>
  );
};
export default EmployeeProfileSettingsPage;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SearchCategoryItemProps = {
  key: string;
  name: string;
  icon: string;
  backgroundColour: string;
  textColour: string;
  borderColour?: string;
  link?: string;
};

export const SearchCategoryItem = ({
  name,
  icon,
  backgroundColour,
  textColour,
  borderColour,
  link,
}: SearchCategoryItemProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid container item xs={12} sm={6} md={4} justifyContent='center'>
      <button
        className='dashboard-category-item'
        aria-label={`navigation ${name} button`}
        style={{
          color: textColour,
          backgroundColor: backgroundColour,
          border: borderColour && `1px solid ${borderColour}`,
        }}
        onClick={() => {
          navigate(link);
        }}
      >
        <Box className='dashboard-category-icon'>
          <img alt='' src={icon} />
        </Box>
        <Box className='dashboard-category-name'>{t(name)}</Box>
      </button>
    </Grid>
  );
};

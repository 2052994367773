import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useNavigate } from 'react-router-dom';
import { NWClient } from '../../client/NWClient';
import { Helpers } from '../../common/helpers/helpers';
import { REGEXP } from '../../common/constants/regexp';
import { FORM_ITEMS } from '../../common/constants/form_items';
import { ROUTES } from '../../common/constants/routes';
import FormErrorText from '../../common/form-error-text/form-error-text';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';

const ResetPasswordEmailBlock = () => {
  const emailRegExp = REGEXP.EMAIL;
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [errorTextArray, setErrorTextArray] = useState([]);
  const { t } = useTranslation();
  const canSubmit = Boolean(email.trim()) && emailValid;
  const navigate = useNavigate();

  const handlePasswordReset = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      email: email.trim().toLowerCase(),
    };
    NWClient.resetPasswordEmail(data)
      .then(() => {
        setPendingStatus(false);
        navigate(ROUTES.EMAIL_SENT);
      })
      .catch((error) => {
        Helpers.handleRequestError(error.response, setErrorTextArray, setPendingStatus);
      });
  };

  useEffect(() => {
    setEmailValid(emailRegExp.test(email));
  }, [email, emailRegExp]);

  return (
    <ValidatorForm
      className='user-data-form'
      aria-invalid={Boolean(errorTextArray.length)}
      aria-errormessage={FORM_ITEMS.ERROR_CONTAINER_ACCOUNT_EMAIL_ID}
      onSubmit={(e) => handlePasswordReset(e)}
    >
      <TextValidator
        label={t('labels.yourAccountEmailAddress')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        name='email'
        value={email}
        validators={['required', 'isEmail']}
        errorMessages={[t('messages.fieldRequired'), t('messages.emailNotValid')]}
      />
      <FormErrorText
        id={FORM_ITEMS.ERROR_CONTAINER_ACCOUNT_EMAIL_ID}
        errorTextArray={errorTextArray}
      />
      <div className='buttons-row'>
        <Button type='submit' role='button' variant='contained' disabled={!canSubmit} size='large'>
          {t('buttons.resetPassword')}
        </Button>
      </div>
      {pendingStatus ? <ProgressOverlay /> : ''}
    </ValidatorForm>
  );
};

export default ResetPasswordEmailBlock;

import React, { ChangeEvent, useEffect, useState } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { selectUser, fetchUser } from '../../../../slices/user/userSlice';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import EmergencySettingsIcon from '../../../../../static/assets/svg/menu/emergency.svg';
import MenuItem from '@mui/material/MenuItem';
import { RELATIONSHIP } from '../../../../common/constants/relationship';

export const EmergencyContactTab = ({ currentTab }: TabProps) => {
  const token = localStorage.getItem('access_token');
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [relationship, setRelationship] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string | null>('');
  const [email, setEmail] = useState<string | null>('');
  const [address, setAddress] = useState<string | null>('');
  const [phoneNumber, setPhoneNumber] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      first_name: firstName,
      last_name: lastName,
      postal_address: address,
      email_address: email,
      relationship,
      phone_number: phoneNumber,
      employee_details_id: currentUser.details ? currentUser.details.id : null,
    };
    if (!currentUser.details) {
      NWClient.post(token, 'employee_details', { user: currentUser.id }).then(() => {
        dispatch(fetchUser(token))
          .unwrap()
          .then((res) => {
            if (res.details) {
              data.employee_details_id = res.details.id;
              NWClient.post(
                token,
                `employee_details/${res.details.id}/emergency_contact`,
                data,
                true
              )
                .then(() => {
                  setPendingStatus(false);
                  dispatch(fetchUser(token)); //to refactor
                  toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
                })
                .catch(() => {
                  setPendingStatus(false);
                  toast.error(t('messages.errorOccurred'), { theme: 'colored' });
                });
            }
          });
      });
    } else {
      if (!currentUser.details.emergency_contact) {
        NWClient.post(
          token,
          `employee_details/${currentUser.details.id}/emergency_contact`,
          data,
          true
        )
          .then(() => {
            setPendingStatus(false);
            dispatch(fetchUser(token)); //to refactor
            toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      } else {
        NWClient.put(
          token,
          `employee_details/${currentUser.details.id}/emergency_contact`,
          currentUser.details.emergency_contact.id,
          data,
          true,
          true
        )
          .then(() => {
            setPendingStatus(false);
            dispatch(fetchUser(token)); //to refactor
            toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      }
    }
  };

  const setEmergencyContact = () => {
    if (currentUser.details && currentUser.details.emergency_contact) {
      const emergencyContactInstance = currentUser.details.emergency_contact;
      setFirstName(emergencyContactInstance.first_name);
      setLastName(emergencyContactInstance.last_name);
      setEmail(emergencyContactInstance.email_address);
      setAddress(emergencyContactInstance.postal_address);
      setRelationship(emergencyContactInstance.relationship);
      setPhoneNumber(emergencyContactInstance.phone_number);
    }
  };

  const canSubmit = true;

  const relationshipArray = RELATIONSHIP.map((el) => t(el));

  useEffect(() => {
    setLoading(true);
    if (currentUser) {
      setLoading(false);
      setEmergencyContact();
    } else {
      dispatch(fetchUser(token))
        .then(() => {
          setEmergencyContact();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentUser, dispatch, token]);

  return (
    <TabPanel value={currentTab} index={3} prefix={'vertical'}>
      {loading ? (
        <Grid container justifyContent='center'>
          <ProgressOverlay className='position-relative' />
          {/*<CircularProgress/>*/}
        </Grid>
      ) : (
        <>
          <Grid>
            <Grid container item className='setting-panel-header' alignItems='center'>
              <EmergencySettingsIcon />
              <h3>{t('settings.titles.emergencyContact')}</h3>
            </Grid>
            <Divider />
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.firstName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                      name='firstName'
                      value={firstName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.lastName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                      name='lastName'
                      value={lastName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.email')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                      name='email'
                      value={email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.phoneNumber')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPhoneNumber(e.target.value)
                      }
                      name='phoneNumber'
                      value={phoneNumber}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.postalAddress')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setAddress(e.target.value);
                      }}
                      name='address'
                      value={address}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      select
                      label={t('labels.relationship')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setRelationship(e.target.value)
                      }
                      name='relationship'
                      value={relationship}
                    >
                      {relationshipArray.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Grid>
                </Grid>
                <Grid container className='buttons-row' justifyContent='flex-end'>
                  <Button
                    type='submit'
                    role='button'
                    disabled={!canSubmit}
                    variant='outlined'
                    size='large'
                  >
                    {t('buttons.update')}
                  </Button>
                </Grid>
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
        </>
      )}
    </TabPanel>
  );
};

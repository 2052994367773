import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { SettingsMenu } from './components/SettingsMenu';
import { AccountDetailsTab } from './components/tabs/AccountDetailsTab';
import { CompanyDetailsTab } from './components/tabs/CompanyDetailsTab';
import { SubscriptionDetailsTab } from './components/tabs/SubscriptionDetailsTab';
import { CompanyContactsTab } from './components/tabs/CompanyContactsTab';

const SettingsPage = () => {
  const [searchParams] = useSearchParams();

  const getInitialTab = () => {
    const tabParam = searchParams.get('tab');
    if (tabParam === 'subscription') return 3;
    if (tabParam === 'account') return 0;
    if (tabParam === 'company') return 1;
    if (tabParam === 'contacts') return 2;
    return 0;
  };

  const [currentTab, setCurrentTab] = React.useState(getInitialTab());

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
        <SettingsMenu value={currentTab} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12} md={9}>
        <div className='setting-panel mb-3'>
          <AccountDetailsTab currentTab={currentTab} />
          <CompanyDetailsTab currentTab={currentTab} />
          <CompanyContactsTab currentTab={currentTab} />
          <SubscriptionDetailsTab currentTab={currentTab} />
        </div>
      </Grid>
    </Grid>
  );
};
export default SettingsPage;

import React from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import SupplierContactsList from '../../SupplierContactsList';

export const SupplierContactTab = ({ currentTab }: TabProps) => {
  return (
    <TabPanel value={currentTab} index={1} prefix={'vertical'}>
      <>
        <SupplierContactsList />
      </>
    </TabPanel>
  );
};

import React from 'react';
import { Grid } from '@mui/material';
import { useEmployeesForm } from './hooks/useEmployeesForm';
import { InviteEmployeeFormControls } from './InviteEmployeeFormControls';
import Button from '@mui/material/Button';
import { Company } from '../../../../../models/company.model';

interface InviteEmployeesFormProps {
  company: Company;
}

export const InviteEmployeesForm = ({ company }: InviteEmployeesFormProps) => {
  const { employees, handleAddEmployee, handleSubmitForm, handleRemoveForm } = useEmployeesForm();

  return (
    <Grid container className='invite-employees-form'>
      {/*<Grid className='form-header' container item spacing={{ md: 2 }}>
        <Grid item xs={10}>
          Email
        </Grid>
      </Grid>*/}
      {employees.map((row) => (
        <InviteEmployeeFormControls
          company={company}
          employee={row}
          key={row.id}
          onSubmit={handleSubmitForm}
          onRemove={handleRemoveForm}
        />
      ))}
      <Grid container>
        <Button
          variant='text'
          color='secondary'
          disabled={employees.length >= 5}
          onClick={handleAddEmployee}
        >
          Add more
        </Button>
      </Grid>
      {/*<Grid container justifyContent='flex-end'>
          <Button role='button' variant='contained' onClick={onClose}>
            Close
          </Button>
        </Grid>*/}
    </Grid>
  );
};

import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import BrandLogo from '../../../static/assets/svg/brand.svg';
import BrandName from '../../../static/assets/svg/brand-name.svg';
import { StyledDrawer } from './components/Drawer';
import { MenuButton } from './components/MenuButton';
import { NavList } from './components/NavList';
import { useSideNavbar } from './hooks/useSideNavbar';

const NWSideNav = (): JSX.Element => {
  const { isOpen, toggle } = useSideNavbar();

  const max991 = useMediaQuery('(max-width: 991px)');

  return (
    <Box component='nav' aria-label='mailbox folders'>
      <StyledDrawer
        variant='permanent'
        className='nw-side-nav'
        open={isOpen}
        anchor={'top'}
        onClose={toggle}
      >
        <Box className='nw-side-nav-container'>
          <Grid container className='nw-side-nav-header'>
            {(isOpen || max991) && (
              <Grid item className='brand-name'>
                <BrandLogo />
                <BrandName />
              </Grid>
            )}
            <Grid item>
              <MenuButton toggle={toggle} isOpen={isOpen} />
            </Grid>
          </Grid>
          <NavList toggle={toggle} isOpen={isOpen} />
        </Box>
      </StyledDrawer>
    </Box>
  );
};
export default NWSideNav;

import React from 'react';
import { MenuItem, MenuProps, Select } from '@mui/material';
import { useSelectField } from './hooks/useSelectField';

interface Option {
  value: string | number;
  label: string;
  className?: string;
}

interface SelectFieldProps {
  options: Option[];
  onChange: (selected: string) => void;
  className?: string;
  optionProps?: {
    className?: string;
  };
  defaultValue?: string;
  disabled?: boolean;
  label?: string;
}

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    sx: {
      maxHeight: 240,
      maxWidth: 240,
    },
  },
};

export const SelectField = ({
  options,
  onChange,
  className,
  defaultValue,
  optionProps,
  disabled,
}: SelectFieldProps) => {
  const { selectedOption, handleChange } = useSelectField({ onChange, defaultValue });

  return (
    <Select
      MenuProps={menuProps}
      variant='standard'
      className={className}
      inputProps={{ title: 'Select a category' }}
      value={selectedOption}
      disabled={disabled}
      onChange={handleChange}
    >
      {options.map((option) => (
        <MenuItem
          sx={{ fontSize: 'initial' }}
          key={option.value}
          className={`${optionProps.className} ${option.className}`}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

import React, { useEffect, useState } from 'react';
import { CircularProgress, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavListItem } from './NavListItem';

import Home from '../../../../static/assets/svg/menu/home.svg';
import Category from '../../../../static/assets/svg/menu/business-dashboard-icon.svg';
import Pricing from '../../../../static/assets/svg/menu/pricing.svg';
import Settings from '../../../../static/assets/svg/menu/settings.svg';
import Employee from '../../../../static/assets/svg/menu/employment-icon.svg';
import Supplier from '../../../../static/assets/svg/menu/business-icon.svg';
import Vault from '../../../../static/assets/svg/menu/vault-icon.svg';
import Signing from '../../../../static/assets/svg/menu/documents-signing.svg';
import Customer from '../../../../static/assets/svg/menu/customers.svg';
import { ROUTES } from '../../constants/routes';
import { RolesGate } from '../../roles-gate';
import { GROUPS } from '../../constants/groups';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { selectAllCompanies, fetchCompanies } from '../../../slices/companies/companiesSlice';
import { useLocation } from 'react-router-dom';
import { ProgressOverlay } from '../../progress-overlay/ProgressOverlay';

export type NavListProps = {
  isOpen: boolean;
  toggle: () => void;
};

export const NavList = ({ isOpen, toggle }: NavListProps) => {
  const token = localStorage.getItem('access_token');
  const { t } = useTranslation();
  const company = useAppSelector(selectAllCompanies)[0];
  const location = useLocation();
  const [companyFetched, setCompanyFetched] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const showPricingMenuItem =
    !company ||
    !subscription ||
    (subscription.status !== 'active' && subscription.status !== 'trialing');
  useEffect(() => {
    if (!companyFetched && !company && token) {
      setLoading(true);
      dispatch(fetchCompanies(token))
        .then((res) => {
          setCompanyFetched(true);
          if (Array.isArray(res) && res.length > 0) {
            setSubscription(res[0].stripe_subscription);
          }
        })
        .finally(() => setLoading(false));
    } else if (company && !subscription) {
      setSubscription(company.stripe_subscription);
    }
  }, [companyFetched, company, subscription, showPricingMenuItem, dispatch, token]);
  return isOpen ? (
    loading ? (
      <div className='d-flex justify-content-center'>
        {/*<CircularProgress/>*/}
        <ProgressOverlay className='position-relative' />
      </div>
    ) : (
      <List aria-label='navigation' aria-hidden={isOpen} role='navigation' tabIndex={0}>
        <NavListItem
          title={t('pages.home')}
          toggle={toggle}
          to={ROUTES.INDEX}
          isActive={location.pathname === ROUTES.INDEX}
        >
          <Home />
        </NavListItem>
        <RolesGate group={GROUPS.COMPANY_ADMIN}>
          {(process.env.ENV_TYPE === 'DEV' || window.location.host.includes('com.au')) && (
            <NavListItem
              title={t('pages.templateCategories')}
              toggle={toggle}
              to={ROUTES.DOCUMENT_LIBRARY}
              isActive={location.pathname === ROUTES.DOCUMENT_LIBRARY}
            >
              <Category />
            </NavListItem>
          )}
          <NavListItem
            title={t('pages.documentVault')}
            toggle={toggle}
            to={ROUTES.DOCUMENT_VAULT}
            isActive={location.pathname === ROUTES.DOCUMENT_VAULT}
          >
            <Vault />
          </NavListItem>
          <NavListItem
            title={t('pages.signing')}
            toggle={toggle}
            to={ROUTES.DOCUMENTS}
            isActive={location.pathname === ROUTES.DOCUMENTS}
          >
            <Signing />
          </NavListItem>
          <NavListItem
            title={t('pages.employeeProfiles')}
            toggle={toggle}
            to={
              company
                ? ROUTES.EMPLOYEE_PROFILES
                : ROUTES.COMPANY_CREATE + '?backUrl=' + ROUTES.EMPLOYEE_PROFILES
            }
            isActive={location.pathname === ROUTES.EMPLOYEE_PROFILES}
          >
            <Employee />
          </NavListItem>
          <NavListItem
            title={t('pages.customerProfiles')}
            toggle={toggle}
            to={
              company
                ? ROUTES.CUSTOMER_PROFILES
                : ROUTES.COMPANY_CREATE + '?backUrl=' + ROUTES.CUSTOMER_PROFILES
            }
            isActive={location.pathname === ROUTES.CUSTOMER_PROFILES}
          >
            <Customer />
          </NavListItem>
          <NavListItem
            title={t('pages.supplierProfiles')}
            toggle={toggle}
            to={
              company
                ? ROUTES.SUPPLIER_PROFILES
                : ROUTES.COMPANY_CREATE + '?backUrl=' + ROUTES.SUPPLIER_PROFILES
            }
            isActive={location.pathname === ROUTES.SUPPLIER_PROFILES}
          >
            <Supplier />
          </NavListItem>
          {showPricingMenuItem && (
            <NavListItem
              title={t('pages.plan')}
              toggle={toggle}
              to={ROUTES.PRICING}
              isActive={location.pathname === ROUTES.PRICING}
            >
              <Pricing />
            </NavListItem>
          )}
        </RolesGate>
        <RolesGate group={GROUPS.COMPANY_EMPLOYEE}>
          <NavListItem
            title={t('pages.employeeProfile')}
            toggle={toggle}
            to={ROUTES.EMPLOYEE_PROFILE_SETTINGS}
            isActive={location.pathname === ROUTES.EMPLOYEE_PROFILE_SETTINGS}
          >
            <Employee />
          </NavListItem>
        </RolesGate>
        <NavListItem
          title={t('pages.settings')}
          toggle={toggle}
          to={ROUTES.SETTINGS}
          isActive={location.pathname === ROUTES.SETTINGS}
        >
          <Settings />
        </NavListItem>
      </List>
    )
  ) : null;
};

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { PropsWithChildren } from 'react';

export interface NwListItemNavProps {
  title?: string;
  to?: string;
  toggle: () => void;
  isActive: boolean;
}

export const NavListItem = ({
  title = '',
  to = '/',
  children,
  toggle,
  isActive,
}: PropsWithChildren<NwListItemNavProps>) => (
  <ListItem
    className='nw-side-nav-link'
    disablePadding
    aria-label={`navigation link ${title}`}
    tabIndex={0}
  >
    <Link
      onClick={toggle}
      to={to}
      className={`nw-side-nav-link-button ${isActive ? 'active' : ''}`}
    >
      <ListItemButton tabIndex={null}>
        <ListItemIcon className='nw-side-nav-link-button-icon'>{children}</ListItemIcon>
        <ListItemText className='nw-side-nav-link-button-title' primary={title}></ListItemText>
      </ListItemButton>
    </Link>
  </ListItem>
);

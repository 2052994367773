import React, { useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { NWClient } from '../../../../../../client/NWClient';
import { StyledTableCell } from '../TableCell';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../../../../../common/dialog-item/DialogItem';
import { Link, useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { ROUTES } from '../../../../../../common/constants/routes';
import { Helpers } from '../../../../../../common/helpers/helpers';
import ContractGeneratorDialog from '../ContractGeneratorDialog';
import { ProgressOverlay } from '../../../../../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../../../../../slices/companies/companiesSlice';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppDispatch } from '../../../../../../common/hooks/useAppDispatch';
import { deleteDocumentTemplate } from '../../../../../../slices/document-templates/documentTemplatesSlice';
import { selectUser } from '../../../../../../slices/user/userSlice';
import { PackmanLoader } from '../../../../../../common/packman-loader';
import { selectAllDocuments } from '../../../../../../slices/documents/documentsSlice';

export type ActionsCellProps = {
  row: {
    id: number;
    name?: string;
    category?: string;
    region?: string;
    document_upload?: number;
    user?: number;
    updated?: string;
  };
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit';
};

export const ActionsCell = ({ row, align = 'left' }: ActionsCellProps) => {
  const token = localStorage.getItem('access_token');
  const company = useAppSelector(selectAllCompanies)[0];
  const currentUser = useAppSelector(selectUser);
  const documents = useAppSelector(selectAllDocuments).filter((el) => el.user === currentUser.id);
  const dispatch = useAppDispatch();
  const { name } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [errorText, setErrorText] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [generatorOpened, setGeneratorOpened] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteTemplateMessageVisible, setDeleteTemplateMessageVisible] = useState<boolean>(false);
  const [templateToDeleteId, setTemplateToDeleteId] = useState<number | null>(null);
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);

  const errorMessageTitle = t('messages.errorOccurred');
  const NoPermissionMessage = () => {
    return (
      <>
        {t('messages.startSubscription')}&nbsp;<Link to={ROUTES.PRICING}>{t('links.here')}</Link>.
      </>
    );
  };
  const noPermissionTitle = t('messages.downloadMessageTitle');

  const setError = (errorMessage: string | null) => {
    setErrorState(true);
    setErrorText(errorMessage);
  };

  const MenuBlock = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div className='d-inline-flex'>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem style={{ padding: 0 }}>
            <Button
              style={{ width: '100%', borderRadius: 0 }}
              onClick={() => {
                navigate(`${ROUTES.DOCUMENT_LIBRARY}/${name}/${row.id}?templateViewEditMode=true`);
              }}
              className='templates-table-action'
              aria-label={`view ${row.name} button`}
              disabled={deleting}
            >
              {!deleting ? t('buttons.edit') : <CircularProgress size={20} />}
            </Button>
          </MenuItem>
          {name === 'company-templates' && (
            <MenuItem style={{ padding: 0 }}>
              <Button
                style={{ width: '100%', borderRadius: 0 }}
                onClick={() => {
                  setTemplateToDeleteId(row.id);
                  setDeleteTemplateMessageVisible(true);
                }}
                className='templates-table-action'
                aria-label={`delete ${row.name} button`}
                disabled={deleting}
              >
                {!deleting ? t('buttons.delete') : <CircularProgress size={20} />}
              </Button>
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  };

  const handleDownloadLink = (id: number) => {
    setDownloadLoading(true);
    NWClient.downloadTemplate(token, id, row.name)
      .catch((error) => {
        const status = error.response && error.response.status;
        setErrorStatus(status);
        const errorMessage = Helpers.returnErrorText(error);
        status === 405
          ? navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${location.pathname}`)
          : status !== 403
          ? setError(errorMessage.toString())
          : setError(null);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const handleDelete = (id: number) => {
    setDeleting(true);
    setDeleteTemplateMessageVisible(false);
    setPendingStatus(true);

    if (name === 'company-templates') {
      dispatch(deleteDocumentTemplate({ token, id, userId: currentUser.id }))
        .unwrap()
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setDeleting(false);
          setTemplateToDeleteId(null);
        });
    }
  };

  const handleGenerateTemplate = (data: {
    user: number;
    document_library?: number;
    document_template?: number;
    company_id: number;
    secondary_id: number;
    category: string;
  }) => {
    setAnalysisLoading(true);
    NWClient.post(token, 'ai-contract', data)
      .then(
        (res: {
          id: number;
          user: number;
          document_library?: number;
          document_template?: number;
          placeholder_matches: { [key: string]: string };
          category: string;
        }) => {
          toast.success(t('messages.contractGenerated'), { theme: 'colored' });
          navigate(
            `${
              name === 'uk' || name === 'au'
                ? `${ROUTES.DOCUMENT_LIBRARY}/${row.region.toLowerCase()}/`
                : `${ROUTES.DOCUMENT_LIBRARY}/${name}/`
            }${res.id}`
          );
        }
      )
      .catch((error) => {
        const status = error.response && error.response.status;
        setErrorStatus(status);
        const errorMessage = Helpers.returnErrorText(error);
        setErrorState(true);
        status !== 403 ? setErrorText(errorMessage.toString()) : setErrorText(null);
        setAnalysisLoading(false);
      });
  };

  return (
    <>
      <StyledTableCell align={align}>
        <Grid container justifyContent='flex-end'>
          <Grid item xs={12} className='d-flex align-items-center justify-content-end'>
            {name !== 'uk' && name !== 'au' && name !== 'company-templates' && (
              <Button
                onClick={() => {
                  handleDownloadLink(row.id);
                }}
                className='templates-table-action'
                aria-label={`download ${row.name} button`}
                disabled={downloadLoading}
              >
                {!downloadLoading ? t('buttons.download') : <CircularProgress size={20} />}
              </Button>
            )}
            {!process.env.ENV_TYPE ||
            ((name === 'uk' || name === 'au' || name === 'company-templates') && company) ? (
              <Button
                onClick={() => {
                  setGeneratorOpened(true);
                }}
                className='templates-table-action'
                aria-label={`create ${row.name} button`}
                disabled={downloadLoading}
              >
                {t('buttons.createContract')}
              </Button>
            ) : null}
            <MenuBlock />
          </Grid>
        </Grid>
        <DialogItem
          isErrorMessage={errorState && errorStatus !== 403}
          open={errorState}
          title={errorStatus !== 403 ? errorMessageTitle : noPermissionTitle}
          text={errorText}
          handleClose={() => setErrorState(false)}
        >
          {errorStatus === 403 ? <NoPermissionMessage /> : null}
        </DialogItem>
        {deleteTemplateMessageVisible && (
          <DialogItem
            isErrorMessage={false}
            open={deleteTemplateMessageVisible}
            title={
              deleteTemplateMessageVisible
                ? t('dialogTitles.deleteTemplate')
                : t('dialogTitles.deleteDefault')
            }
            text={
              deleteTemplateMessageVisible
                ? t('messages.deleteTemplate')
                : t('messages.deleteDefault')
            }
            noDefaultActionsRow={true}
            handleClose={() => {
              setDeleteTemplateMessageVisible(false);
            }}
          >
            <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
              <Button
                onClick={() => handleDelete(templateToDeleteId)}
                style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                type='button'
                role='button'
                variant='outlined'
                size='medium'
              >
                {t('buttons.deleteTemplate')}
              </Button>
              <Button
                type='button'
                role='button'
                variant='contained'
                size='medium'
                style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                onClick={() => {
                  setDeleteTemplateMessageVisible(false);
                  setTemplateToDeleteId(null);
                }}
              >
                {t('buttons.goBack')}
              </Button>
            </div>
          </DialogItem>
        )}
        {company && (
          <ContractGeneratorDialog
            templateId={row.id}
            open={generatorOpened}
            handleClose={() => setGeneratorOpened(false)}
            templateTitle={
              row.name ||
              ('document_upload' in row && row.document_upload
                ? documents.length > 0
                  ? documents.find((el) => el.id === row.document_upload).name + '_' + row.updated
                  : ''
                : '')
            }
            handleGenerateTemplate={handleGenerateTemplate}
          />
        )}
        {analysisLoading ? <PackmanLoader loading={analysisLoading} /> : null}
        {pendingStatus ? <ProgressOverlay /> : ''}
      </StyledTableCell>
    </>
  );
};

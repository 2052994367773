export const CURRENCIES_LIST = [
  { name: 'Afghan afghani', code: 'AFA' },
  { name: 'Albanian lek', code: 'ALL' },
  { name: 'Algerian dinar', code: 'DZD' },
  { name: 'Angolan kwanza', code: 'AOA' },
  { name: 'Argentine peso', code: 'ARS' },
  { name: 'Armenian dram', code: 'AMD' },
  { name: 'Aruban florin', code: 'AWG' },
  { name: 'Australian dollar', code: 'AUD' },
  { name: 'Azerbaijani manat', code: 'AZN' },
  { name: 'Bahamian dollar', code: 'BSD' },
  { name: 'Bahraini dinar', code: 'BHD' },
  { name: 'Bangladeshi taka', code: 'BDT' },
  { name: 'Barbadian dollar', code: 'BBD' },
  { name: 'Belarusian ruble', code: 'BYR' },
  { name: 'Belgian franc', code: 'BEF' },
  { name: 'Belize dollar', code: 'BZD' },
  { name: 'Bermudan dollar', code: 'BMD' },
  { name: 'Bhutanese ngultrum', code: 'BTN' },
  { name: 'Bitcoin', code: 'BTC' },
  { name: 'Bolivian boliviano', code: 'BOB' },
  { name: 'Bosnia-Herzegovina convertible mark', code: 'BAM' },
  { name: 'Botswanan pula', code: 'BWP' },
  { name: 'Brazilian real', code: 'BRL' },
  { name: 'British pound sterling', code: 'GBP' },
  { name: 'Brunei dollar', code: 'BND' },
  { name: 'Bulgarian lev', code: 'BGN' },
  { name: 'Burundian franc', code: 'BIF' },
  { name: 'Cambodian riel', code: 'KHR' },
  { name: 'Canadian dollar', code: 'CAD' },
  { name: 'Cape Verdean escudo', code: 'CVE' },
  { name: 'Cayman Islands dollar', code: 'KYD' },
  { name: 'CFA franc BCEAO', code: 'XOF' },
  { name: 'CFA franc BEAC', code: 'XAF' },
  { name: 'CFP franc', code: 'XPF' },
  { name: 'Chilean peso', code: 'CLP' },
  { name: 'Chilean unit of account', code: 'CLF' },
  { name: 'Chinese yuan', code: 'CNY' },
  { name: 'Colombian peso', code: 'COP' },
  { name: 'Comorian franc', code: 'KMF' },
  { name: 'Congolese franc', code: 'CDF' },
  { name: 'Costa Rican colón', code: 'CRC' },
  { name: 'Croatian kuna', code: 'HRK' },
  { name: 'Cuban convertible peso', code: 'CUC' },
  { name: 'Czech Republic koruna', code: 'CZK' },
  { name: 'Danish krone', code: 'DKK' },
  { name: 'Djiboutian franc', code: 'DJF' },
  { name: 'Dominican peso', code: 'DOP' },
  { name: 'East Caribbean dollar', code: 'XCD' },
  { name: 'Egyptian pound', code: 'EGP' },
  { name: 'Eritrean nakfa', code: 'ERN' },
  { name: 'Estonian kroon', code: 'EEK' },
  { name: 'Ethiopian birr', code: 'ETB' },
  { name: 'Euro', code: 'EUR' },
  { name: 'Falkland Islands pound', code: 'FKP' },
  { name: 'Fijian dollar', code: 'FJD' },
  { name: 'Gambian dalasi', code: 'GMD' },
  { name: 'Georgian lari', code: 'GEL' },
  { name: 'German mark', code: 'DEM' },
  { name: 'Ghanaian cedi', code: 'GHS' },
  { name: 'Gibraltar pound', code: 'GIP' },
  { name: 'Greek drachma', code: 'GRD' },
  { name: 'Guatemalan quetzal', code: 'GTQ' },
  { name: 'Guinean franc', code: 'GNF' },
  { name: 'Guyanaese dollar', code: 'GYD' },
  { name: 'Haitian gourde', code: 'HTG' },
  { name: 'Honduran lempira', code: 'HNL' },
  { name: 'Hong Kong dollar', code: 'HKD' },
  { name: 'Hungarian forint', code: 'HUF' },
  { name: 'Icelandic króna', code: 'ISK' },
  { name: 'Indian rupee', code: 'INR' },
  { name: 'Indonesian rupiah', code: 'IDR' },
  { name: 'Iranian rial', code: 'IRR' },
  { name: 'Iraqi dinar', code: 'IQD' },
  { name: 'Israeli new sheqel', code: 'ILS' },
  { name: 'Italian lira', code: 'ITL' },
  { name: 'Jamaican dollar', code: 'JMD' },
  { name: 'Japanese yen', code: 'JPY' },
  { name: 'Jordanian dinar', code: 'JOD' },
  { name: 'Kazakhstani tenge', code: 'KZT' },
  { name: 'Kenyan shilling', code: 'KES' },
  { name: 'Kuwaiti dinar', code: 'KWD' },
  { name: 'Kyrgystani som', code: 'KGS' },
  { name: 'Laotian kip', code: 'LAK' },
  { name: 'Latvian lats', code: 'LVL' },
  { name: 'Lebanese pound', code: 'LBP' },
  { name: 'Lesotho loti', code: 'LSL' },
  { name: 'Liberian dollar', code: 'LRD' },
  { name: 'Libyan dinar', code: 'LYD' },
  { name: 'Litecoin', code: 'LTC' },
  { name: 'Lithuanian litas', code: 'LTL' },
  { name: 'Macanese pataca', code: 'MOP' },
  { name: 'Macedonian denar', code: 'MKD' },
  { name: 'Malagasy ariary', code: 'MGA' },
  { name: 'Malawian kwacha', code: 'MWK' },
  { name: 'Malaysian ringgit', code: 'MYR' },
  { name: 'Maldivian rufiyaa', code: 'MVR' },
  { name: 'Mauritanian ouguiya', code: 'MRO' },
  { name: 'Mauritian rupee', code: 'MUR' },
  { name: 'Mexican peso', code: 'MXN' },
  { name: 'Moldovan leu', code: 'MDL' },
  { name: 'Mongolian tugrik', code: 'MNT' },
  { name: 'Moroccan dirham', code: 'MAD' },
  { name: 'Mozambican metical', code: 'MZM' },
  { name: 'Myanmar kyat', code: 'MMK' },
  { name: 'Namibian dollar', code: 'NAD' },
  { name: 'Nepalese rupee', code: 'NPR' },
  { name: 'Netherlands Antillean guilder', code: 'ANG' },
  { name: 'New Taiwan dollar', code: 'TWD' },
  { name: 'New Zealand dollar', code: 'NZD' },
  { name: 'Nicaraguan córdoba', code: 'NIO' },
  { name: 'Nigerian naira', code: 'NGN' },
  { name: 'North Korean won', code: 'KPW' },
  { name: 'Norwegian krone', code: 'NOK' },
  { name: 'Omani rial', code: 'OMR' },
  { name: 'Pakistani rupee', code: 'PKR' },
  { name: 'Panamanian balboa', code: 'PAB' },
  { name: 'Papua New Guinean kina', code: 'PGK' },
  { name: 'Paraguayan guarani', code: 'PYG' },
  { name: 'Peruvian Nuevo sol', code: 'PEN' },
  { name: 'Philippine peso', code: 'PHP' },
  { name: 'Polish zloty', code: 'PLN' },
  { name: 'Qatari rial', code: 'QAR' },
  { name: 'Romanian leu', code: 'RON' },
  { name: 'Russian ruble', code: 'RUB' },
  { name: 'Rwandan franc', code: 'RWF' },
  { name: 'Salvadoran colón', code: 'SVC' },
  { name: 'Samoan tala', code: 'WST' },
  { name: 'São Tomé and Príncipe dobra', code: 'STD' },
  { name: 'Saudi riyal', code: 'SAR' },
  { name: 'Serbian dinar', code: 'RSD' },
  { name: 'Seychellois rupee', code: 'SCR' },
  { name: 'Sierra Leonean leone', code: 'SLL' },
  { name: 'Singapore dollar', code: 'SGD' },
  { name: 'Slovak koruna', code: 'SKK' },
  { name: 'Solomon Islands dollar', code: 'SBD' },
  { name: 'Somali shilling', code: 'SOS' },
  { name: 'South African rand', code: 'ZAR' },
  { name: 'South Korean won', code: 'KRW' },
  { name: 'South Sudanese pound', code: 'SSP' },
  { name: 'Special Drawing rights', code: 'XDR' },
  { name: 'Sri Lankan rupee', code: 'LKR' },
  { name: 'St. Helena pound', code: 'SHP' },
  { name: 'Sudanese pound', code: 'SDG' },
  { name: 'Surinamese dollar', code: 'SRD' },
  { name: 'Swazi lilangeni', code: 'SZL' },
  { name: 'Swedish krona', code: 'SEK' },
  { name: 'Swiss franc', code: 'CHF' },
  { name: 'Syrian pound', code: 'SYP' },
  { name: 'Tajikistani somoni', code: 'TJS' },
  { name: 'Tanzanian shilling', code: 'TZS' },
  { name: 'Thai baht', code: 'THB' },
  { name: "Tongan pa'anga", code: 'TOP' },
  { name: 'Trinidad & Tobago dollar', code: 'TTD' },
  { name: 'Tunisian dinar', code: 'TND' },
  { name: 'Turkish lira', code: 'TRY' },
  { name: 'Turkmenistani manat', code: 'TMT' },
  { name: 'Ugandan shilling', code: 'UGX' },
  { name: 'Ukrainian hryvnia', code: 'UAH' },
  { name: 'United Arab Emirates dirham', code: 'AED' },
  { name: 'Uruguayan peso', code: 'UYU' },
  { name: 'United States dollar', code: 'USD' },
  { name: 'Uzbekistan som', code: 'UZS' },
  { name: 'Vanuatu vatu', code: 'VUV' },
  { name: 'Venezuelan bolívar', code: 'VEF' },
  { name: 'Vietnamese dong', code: 'VND' },
  { name: 'Yemeni rial', code: 'YER' },
  { name: 'Zambian kwacha', code: 'ZMK' },
  { name: 'Zimbabwean dollar', code: 'ZWL' },
];

import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/user/userSlice';

export const useGreetingUser = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const now = moment();
  const hours = now.hours();

  const getWelcoming = () => {
    if (hours >= 4 && hours < 12) {
      return t('welcomingText.morning');
    }
    if (hours >= 12 && hours < 18) {
      return t('welcomingText.afternoon');
    }
    if (hours >= 18 && hours < 22) {
      return t('welcomingText.evening');
    }
    if ((hours >= 22 && hours < 24) || (hours >= 0 && hours < 4)) {
      return t('welcomingText.night');
    }
  };

  return {
    greeting: `${getWelcoming()}${user?.first_name ? `, ${user.first_name} ` : ''}`,
    date: moment().format('dddd, MMMM D'),
  };
};

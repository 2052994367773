import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import TemplateDashboardSearchBar from '../templates/TemplateDashboardSearchBar';
import { useCategory } from './hooks/useCategory';
import { TemplatesTable } from './components/TemplatesTable';
import CategoriesPage from '../categories/CategoriesPage';
import SVG from 'react-inlinesvg';
import { getResourceLink, getSvgIconColorByCategory } from '../../common/utils';
import { useParams, useNavigate } from 'react-router-dom';
import { NWClient } from '../../client/NWClient';
import { HTMLTemplateModel } from '../../models/html.template.model';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { ROUTES } from '../../common/constants/routes';
import DialogItem from '../../common/dialog-item/DialogItem';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { fetchDocuments, selectAllDocuments } from '../../slices/documents/documentsSlice';
import Tooltip from '@mui/material/Tooltip';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import {
  fetchDocumentTemplates,
  selectAllDocumentTemplates,
} from '../../slices/document-templates/documentTemplatesSlice';

export const CategoryPage = () => {
  const token = localStorage.getItem('access_token');
  const { searchCategories, category } = useCategory();
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const documents = useAppSelector(selectAllDocuments).filter((el) => el.user === currentUser.id);
  const templates = useAppSelector(selectAllDocumentTemplates);
  const navigate = useNavigate();
  const { name } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [createTemplateDialogVisible, setCreateTemplateDialogVisible] = useState<boolean>(false);
  const [regionTemplates, setRegionTemplates] = useState<HTMLTemplateModel[]>([]);

  const getSpecificHeaders = () => {
    switch (name) {
      case 'uk':
        return t('countries.uk');
      case 'au':
        return t('countries.au');
      case 'company-templates':
        return t('companyTemplatesHeader');
      default:
        return name;
    }
  };

  useEffect(() => {
    if (name === 'uk' || name === 'au') {
      setLoading(true);
      NWClient.list(token, 'document-library', { region: name }).then(
        (res: HTMLTemplateModel[]) => {
          setLoading(false);
          setRegionTemplates(res);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (name === 'company-templates') {
      setLoading(true);
      if (documents.length === 0) {
        dispatch(fetchDocuments({ token, data: { user: currentUser.id } }));
      }
      dispatch(fetchDocumentTemplates({ token, data: { user: currentUser.id } }))
        .unwrap()
        .catch(() => {
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (category && category.sub_categories.length > 0) {
    return <CategoriesPage />;
  }

  if (!category && name !== 'uk' && name !== 'au' && name !== 'company-templates') {
    return (
      <Grid container justifyContent='center'>
        <ProgressOverlay className='position-relative' />
        {/*<CircularProgress/>*/}
      </Grid>
    );
  }

  return (
    <>
      <Grid container flexDirection='column' spacing={4} alignItems='center'>
        <Grid
          wrap={'nowrap'}
          container
          xs={12}
          sm={12}
          item
          justifyContent='center'
          alignItems='center'
        >
          <Grid item>
            <SVG
              color={getSvgIconColorByCategory(category?.parentName)}
              preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
              description='category icon'
              className='category-icon'
              src={getResourceLink(category?.icon)}
            />
          </Grid>
          <Grid item className='w-100 d-flex justify-content-space-between align-items-start'>
            <Typography
              className={'category-name text-center w-100'}
              component={'h1'}
              gutterBottom={false}
            >
              {category ? category.name : getSpecificHeaders()}
            </Typography>
            {name === 'company-templates' && templates.length > 0 && (
              <Tooltip title={t('buttons.createTemplate')} placement='top' arrow>
                <IconButton
                  style={{ marginLeft: '0.5rem', flexShrink: 0 }}
                  onClick={() => navigate(ROUTES.DOCUMENT_VAULT)}
                >
                  <SVG
                    src={'/assets/svg/generate-template-icon.svg'}
                    style={{ width: 30, height: 30, stroke: '#6414DB', fill: '#6414DB' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        {name !== 'uk' && name !== 'au' && name !== 'company-templates' && (
          <Grid container item justifyContent='center'>
            <TemplateDashboardSearchBar
              filtered
              categories={searchCategories}
              placeholder={'Enter a template name here, choose it from the list below if found'}
            />
          </Grid>
        )}
        {name === 'company-templates' && templates.length === 0 && (
          <Grid container item justifyContent='center'>
            <Grid item xs={12}>
              <p className='text-center mb-4'>{t('messages.templatesFromVault')}</p>
              <div className='text-center'>
                <IconButton
                  style={{ borderRadius: 30, padding: 20 }}
                  onClick={() => setCreateTemplateDialogVisible(true)}
                >
                  <SVG
                    src={'/assets/svg/generate-template-icon.svg'}
                    style={{ width: 30, height: 30, stroke: '#6414DB', fill: '#6414DB' }}
                  />
                  <span style={{ marginLeft: 10 }}>{t('buttons.createTemplate')}</span>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container item justifyContent='center'>
          <TemplatesTable
            templates={
              category
                ? category.templates
                : name === 'company-templates'
                ? templates
                : regionTemplates
            }
          />
        </Grid>
      </Grid>
      {createTemplateDialogVisible && (
        <DialogItem
          isErrorMessage={false}
          open={createTemplateDialogVisible}
          title={t('dialogTitles.createTemplate')}
          text={
            documents.length > 0
              ? t('messages.templateFromVaultWithDocs')
              : t('messages.templateFromVaultEmpty')
          }
          noDefaultActionsRow={true}
          handleClose={() => {
            setCreateTemplateDialogVisible(false);
          }}
        >
          <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
            <Button
              type='button'
              role='button'
              variant='outlined'
              size='medium'
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => {
                setCreateTemplateDialogVisible(false);
              }}
            >
              {t('buttons.goBack')}
            </Button>
            <Button
              onClick={() => navigate(ROUTES.DOCUMENT_VAULT)}
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='contained'
              size='medium'
            >
              {t('buttons.openVault')}
            </Button>
          </div>
        </DialogItem>
      )}
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};

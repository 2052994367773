import React, { ChangeEvent, useState, useRef } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TabProps } from './types';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectAllDocuments } from '../../../../slices/documents/documentsSlice';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import AIIcon from '../../../../../static/assets/svg/ai-icon.svg';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { NWClient } from '../../../../client/NWClient';
import { toast } from 'react-toastify';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import { selectUser } from '../../../../slices/user/userSlice';
import { selectAllDocumentContracts } from '../../../../slices/document-contracts/documentContractsSlice';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const DocumentSummaryTab = ({ currentTab, summary }: TabProps) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const contractGenerated = params.get('contractGenerated');
  const purposes = [
    {
      title: t('purpose.verification'),
      value: 'verification',
    },
    {
      title: t('purpose.analysis'),
      value: 'analysis',
    },
    {
      title: t('purpose.recordKeeping'),
      value: 'record keeping',
    },
  ];
  const token = localStorage.getItem('access_token');
  const company = useAppSelector(selectAllCompanies)[0];
  const { documentId } = useParams();
  const currentUser = useAppSelector(selectUser);
  const document = useAppSelector(selectAllDocuments).find((el) => el.id === Number(documentId));
  const documentContract = useAppSelector(selectAllDocumentContracts).find(
    (el) => el.id === Number(documentId)
  );
  const location = useLocation();
  const path = location.pathname;
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [aiDocumentDialogVisible, setAiDocumentDialogVisible] = useState(false);
  const [documentType, setDocumentType] = useState('contract');
  const [purpose, setPurpose] = useState(purposes);
  const [resultFormat, setResultFormat] = useState('table');
  const [detailLevel, setDetailLevel] = useState('high level');
  const [simplifyJargon, setSimplifyJargon] = useState(true);
  const [contextualInformation, setContextualInformation] = useState('');
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);
  const divElement = useRef(null);

  const handleAnalyze = () => {
    setAnalysisLoading(true);
    NWClient.post(
      token,
      'ai-assist',
      {
        [contractGenerated ? 'document_contract_id' : 'document_upload_id']: Number(documentId),
        document_type: documentType,
        result_format: resultFormat,
        detail_level: detailLevel,
        purpose: JSON.stringify(purpose.map((el) => el.value)),
        simplify_jargon: simplifyJargon,
        contextual_information: contextualInformation,
        user: currentUser.id,
      },
      true
    )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.message ? error.message : t('messages.errorOccurred'), {
          theme: 'colored',
        });
      });
  };

  const copyToClipboard = async () => {
    try {
      const html = divElement.current;

      const success = await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([html.innerHTML], {
            type: 'text/html',
          }),
        }),
      ]);
      toast.success(t('messages.textCopied'), {
        theme: 'colored',
      });
      return success;
    } catch (e) {
      toast.error(t('messages.errorOccurred'), {
        theme: 'colored',
      });
    }
  };

  return (
    <TabPanel value={currentTab} index={0} prefix={'vertical'}>
      <>
        <div className='p-4'>
          <div className='d-flex justify-content-between align-items-start'>
            <h2 className='mb-4 pe-2'>{t('documentTabs.summary')}</h2>
            {summary && (
              <div>
                <Tooltip
                  className='flex-shrink-0'
                  title={t('messages.AISummaryMessage')}
                  placement='top'
                  arrow
                >
                  <IconButton
                    className='table-row-icon-button'
                    onClick={() => {
                      setAiDocumentDialogVisible(true);
                    }}
                  >
                    <AIIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip className='flex-shrink-0' title={t('buttons.copy')} placement='top' arrow>
                  <IconButton
                    className='table-row-icon-button'
                    onClick={() => {
                      copyToClipboard();
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div ref={divElement} className='d-md-flex d-lg-flex w-100'>
            <div className='document-data-container flex-shrink-0'>
              <div className='data-row d-flex'>
                <div className='label'>{t('labels.fileName')}:</div>
                <div className='value-item'>
                  {contractGenerated ? documentContract?.name : document?.name}
                </div>
              </div>
              <div className='data-row d-flex'>
                <div className='label'>{t('labels.profile')}:</div>
                <div className='value-item text-capitalize'>
                  {contractGenerated ? documentContract?.category : document?.category}
                </div>
              </div>
              <div className='data-row d-flex'>
                <div className='label'>{t('labels.connected')}:</div>
                <div className='value-item'>{company?.name}</div>
              </div>
            </div>
            <div className='ps-4 flex-grow-1 summary-block'>
              {summary ? (
                parse(summary)
              ) : (
                <Button
                  type='button'
                  role='button'
                  variant='contained'
                  size='medium'
                  onClick={() => {
                    setAiDocumentDialogVisible(true);
                  }}
                >
                  {t('messages.AISummaryMessage')}
                </Button>
              )}
            </div>
          </div>
        </div>
        {aiDocumentDialogVisible && (
          <DialogItem
            isErrorMessage={false}
            open={aiDocumentDialogVisible}
            title={contractGenerated ? documentContract.name : document.name}
            noDefaultActionsRow={true}
            headerIcon={<AIIcon />}
            handleClose={() => {
              setAiDocumentDialogVisible(false);
            }}
          >
            <div className='d-flex mb-3'>
              <p className='text-center flex-grow-1 ps-2 pe-2'>{t('messages.reviewSettings')}</p>
              <Tooltip
                className='flex-shrink-0'
                title={t('messages.AIFunctionalityMessage')}
                placement='top'
              >
                <InfoIcon fontSize='small' color='secondary' />
              </Tooltip>
            </div>
            <hr />
            <Grid>
              <div className='setting-panel-body ps-0 pe-0'>
                <ValidatorForm onSubmit={() => console.log('')}>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} className='pt-3' md={6}>
                      <InputLabel htmlFor='documentTypeSelect'>
                        {t('labels.documentType')}
                      </InputLabel>
                      <Select
                        className='w-100'
                        id='documentTypeSelect'
                        onChange={(e: SelectChangeEvent<typeof documentType>) =>
                          setDocumentType(e.target.value)
                        }
                        name='documentType'
                        value={documentType}
                        tabIndex={-1}
                        defaultOpen={true}
                      >
                        <MenuItem value='contract'>{t('documentTypes.contract')}</MenuItem>
                        <MenuItem value='insurance'>{t('documentTypes.insurance')}</MenuItem>
                        <MenuItem value='invoice'>{t('documentTypes.invoice')}</MenuItem>
                        <MenuItem value='certificate'>{t('documentTypes.certificate')}</MenuItem>
                        <MenuItem value='other'>{t('documentTypes.other')}</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} className='pt-3' md={6}>
                      <Autocomplete
                        className='mt-0'
                        multiple
                        id='tags-standard'
                        openOnFocus={true}
                        options={purposes}
                        disableCloseOnSelect={true}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        value={purpose}
                        onChange={(
                          event: ChangeEvent<HTMLInputElement>,
                          newValue:
                            | {
                                title: string;
                                value: string;
                              }[]
                            | null
                        ) => {
                          setPurpose(newValue);
                        }}
                        renderOption={(props, option, { selected }) => {
                          const { ...optionProps } = props;
                          return (
                            <li key={option.value} {...optionProps}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.title}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant='standard' label={t('labels.purpose')} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} className='pt-3' md={6}>
                      <TextValidator
                        select
                        label={t('labels.resultFormat')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setResultFormat(e.target.value);
                        }}
                        name='resultFormat'
                        value={resultFormat}
                      >
                        <MenuItem value={''}>{''}</MenuItem>
                        <MenuItem value='table'>{t('resultFormat.table')}</MenuItem>
                        <MenuItem value='bullet point'>{t('resultFormat.bulletPoint')}</MenuItem>
                        <MenuItem value='narrative format'>
                          {t('resultFormat.narrativeFormat')}
                        </MenuItem>
                      </TextValidator>
                    </Grid>
                    <Grid item xs={12} className='pt-3' md={6}>
                      <TextValidator
                        select
                        label={t('labels.detailLevel')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setDetailLevel(e.target.value);
                        }}
                        name='detailLevel'
                        value={detailLevel}
                      >
                        <MenuItem value='high level'>{t('detailLevel.highLevel')}</MenuItem>
                        <MenuItem value='detailed'>{t('detailLevel.detailed')}</MenuItem>
                      </TextValidator>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className='pt-3 checkbox-row d-flex align-items-start'
                    >
                      <Checkbox
                        style={{ marginLeft: -10 }}
                        id='simplifyTechnicalOrLegalJargon'
                        checked={simplifyJargon}
                        onChange={(event) => setSimplifyJargon(event.target.checked)}
                      />
                      <label htmlFor='simplifyTechnicalOrLegalJargon'>
                        {t('labels.simplifyJargon')}
                      </label>
                    </Grid>
                    <Grid item xs={12} className='pt-3' md={12}>
                      <InputLabel htmlFor='contextualInformation'>
                        {t('labels.contextualInformation')}
                      </InputLabel>
                      <textarea
                        id='contextualInformation'
                        name='contextualInformation'
                        value={contextualInformation}
                        placeholder={t('messages.contextualInformationPlaceholder')}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                          setContextualInformation(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className='pt-0 buttons-row d-flex flex-wrap justify-content-end'
                    >
                      <hr className='w-100 mb-3' />
                      <Button
                        type='button'
                        role='button'
                        variant='outlined'
                        size='medium'
                        style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                        onClick={() => {
                          setAiDocumentDialogVisible(false);
                        }}
                      >
                        {t('buttons.goBack')}
                      </Button>
                      <Button
                        onClick={() => handleAnalyze()}
                        style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                        type='button'
                        role='button'
                        variant='contained'
                        size='medium'
                      >
                        {t('buttons.analyze')}
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </div>
            </Grid>
          </DialogItem>
        )}
        {pendingStatus ? <ProgressOverlay /> : ''}
        {analysisLoading ? (
          <div
            className='position-fixed w-100 vh-100 d-flex align-items-center justify-content-center'
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 3000, left: 0, top: 0 }}
          >
            <PacmanLoader loading={analysisLoading} color='#6414DB' />
          </div>
        ) : null}
      </>
    </TabPanel>
  );
};

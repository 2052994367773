import React from 'react';
import { FORM_ITEMS } from '../constants/form_items';

interface ErrorItem {
  [key: string]: string | object;
}

interface FormErrorTextProps {
  errorTextArray: ErrorItem[];
  id?: string;
  textCentered?: boolean;
}

const FormErrorText = (props: FormErrorTextProps) => {
  const { errorTextArray, id, textCentered } = props;
  const text = Array.isArray(errorTextArray)
    ? errorTextArray.map((el) => (
        <div key={Object.keys(el)[0]}>
          {typeof el === 'string' ? Object.values(el) : Object.values(el).toString()}
        </div>
      ))
    : 'An error occurred';
  const classesString = textCentered ? 'mui-danger text-center' : 'mui-danger';
  return (
    <div className={classesString} id={id || FORM_ITEMS.ERROR_CONTAINER_ID}>
      {text}
    </div>
  );
};
export default FormErrorText;

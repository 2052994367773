import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, CircularProgress, Grid } from '@mui/material';
import { BLACK_COLOR, LIGHT_GREY } from '../../common/constants/colors';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import Button from '@mui/material/Button';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { Supplier } from '../../models/supplier.model';
import SupplierProfileForm from './components/SupplierProfileForm';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';

const SupplierProfiles = () => {
  const { t } = useTranslation();
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const company = useAppSelector(selectAllCompanies)[0];
  const suppliers: Supplier[] = company?.suppliers.slice() || [];
  const suppliersSorted =
    suppliers.length > 0 ? suppliers.sort((a, b) => a.name.localeCompare(b.name)) : [];
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (company) {
      setLoading(false);
    }
  }, [company]);

  return (
    <React.Fragment>
      {supplierId ? (
        <SupplierProfileForm />
      ) : (
        <React.Fragment>
          <div className='header-wrapper employees-page-header d-md-flex d-lg-flex justify-content-md-between justify-content-lg-between align-items-md-start align-items-lg-start mb-3'>
            <h1 className='mb-2 fs-2 mb-md-0 mb-lg-0'>{t('supplierProfiles.header')}</h1>
            <div className='controls-wrapper text-center text-md-left text-lg-left'>
              <Button
                type='button'
                variant='outlined'
                size='large'
                onClick={() => navigate(ROUTES.SUPPLIER_PROFILE_CREATE)}
              >
                <PersonAddAltOutlinedIcon sx={{ width: 30, height: 30 }} color='primary' />
                <span>{t('buttons.addSupplier')}</span>
              </Button>
            </div>
          </div>
          <div className='invite-employees-items employees-list d-flex justify-content-center justify-content-md-start flex-wrap'>
            {loading ? (
              <Grid container justifyContent='center'>
                <ProgressOverlay className='position-relative' />
                {/*<CircularProgress/>*/}
              </Grid>
            ) : suppliersSorted.length > 0 ? (
              suppliersSorted.map((supplier) => (
                <div
                  role='button'
                  tabIndex={-1}
                  key={supplier.name}
                  className='invite-employees-item'
                  onClick={() => navigate(ROUTES.SUPPLIER_PROFILES + '/' + supplier.id)}
                  onKeyUp={(e) => console.log(e)}
                >
                  <div className='invite-employees-item-avatar d-flex justify-content-center'>
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        fontSize: '2rem',
                        backgroundColor: LIGHT_GREY,
                        color: BLACK_COLOR,
                      }}
                    >{`${supplier?.name?.[0]}`}</Avatar>
                  </div>
                  <div className='invite-employees-item-name'>{supplier.name}</div>
                  {/*<Grid item className='invite-employees-item-role'>
                {employee.groups.map((group) => (
                    <div key={group.name}>{group.name}</div>
                ))}
              </Grid>*/}
                  {/*<div className='invite-employees-item-contacts'>{}</div>*/}
                </div>
              ))
            ) : (
              <div className='no-employees-message text-center mt-5 ps-5 pe-5'>
                <p className='mb-5 fs-5'>{t('messages.noSuppliers')}</p>
                <p className='mb-0 fs-5 fst-italic'>{t('messages.noSuppliers3')}</p>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SupplierProfiles;

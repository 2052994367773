import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { NWClient } from '../../../../client/NWClient';
import { toast } from 'react-toastify';
import { Divider, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { fetchCompanies } from '../../../../slices/companies/companiesSlice';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import { ROUTES } from '../../../../common/constants/routes';

const CompanyContactItem = (props: {
  contactFirstName: string;
  contactLastName: string;
  employeeId: number;
  contactId: number;
  authorizedRepValue: boolean;
  companyId: number;
}) => {
  const {
    contactFirstName,
    contactLastName,
    contactId,
    authorizedRepValue,
    companyId,
    employeeId,
  } = props;

  const token = localStorage.getItem('access_token');
  const dispatch = useAppDispatch();
  const [deleteContactMessageVisible, setDeleteContactMessageVisible] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const { t } = useTranslation();

  const deleteContact = () => {
    setPendingStatus(true);
    NWClient.delete(token, 'company_contacts', contactId)
      .then(() => {
        toast.success(t('messages.contactDeleted'), { theme: 'colored' });
        dispatch(fetchCompanies(token))
          .unwrap()
          .then(() => {
            setPendingStatus(false);
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      })
      .catch(() => {
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  return (
    <div className='supplier-contact-item'>
      <>
        <Grid>
          <div className='setting-panel-body ps-0 pe-0'>
            <Grid container className='form-controls mb-0' spacing={4}>
              <Grid item xs={6} md={6}>
                <Link to={ROUTES.EMPLOYEE_PROFILES + '/' + String(employeeId)} target='_blank'>
                  {contactFirstName + ' ' + contactLastName}
                </Link>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                className='buttons-row d-flex justify-content-end align-items-center'
              >
                <IconButton
                  aria-label='delete'
                  style={{ marginLeft: '0.5rem', padding: '0' }}
                  onClick={() => setDeleteContactMessageVisible(true)}
                >
                  <Tooltip title={t('messages.deleteContact')} placement='top' arrow>
                    <DeleteOutlineOutlinedIcon fontSize='large' style={{ marginRight: 0 }} />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            {pendingStatus ? <ProgressOverlay /> : ''}
          </div>
        </Grid>
        <Divider color='#000' />
      </>
      <DialogItem
        isErrorMessage={false}
        open={deleteContactMessageVisible}
        title={
          deleteContactMessageVisible
            ? t('dialogTitles.deleteContact')
            : t('dialogTitles.deleteDefault')
        }
        text={
          deleteContactMessageVisible
            ? t('messages.deleteCompanyContactDialog')
            : t('messages.deleteDefault')
        }
        noDefaultActionsRow={true}
        handleClose={() => {
          setDeleteContactMessageVisible(false);
        }}
      >
        <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
          <Button
            onClick={() => deleteContact()}
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            type='button'
            role='button'
            variant='outlined'
            size='medium'
          >
            {t('buttons.deleteContact')}
          </Button>
          <Button
            type='button'
            role='button'
            variant='contained'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => {
              setDeleteContactMessageVisible(false);
            }}
          >
            {t('buttons.goBack')}
          </Button>
        </div>
      </DialogItem>
    </div>
  );
};

export default CompanyContactItem;

import React, { useState, useEffect } from 'react';
import { NWClient } from '../../client/NWClient';
import PriceOption from './PricingOption';
import FormErrorText from '../../common/form-error-text/form-error-text';
import { Helpers } from '../../common/helpers/helpers';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { Price } from '../../models/price.model';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../common/dialog-item/DialogItem';
import Button from '@mui/material/Button';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { selectAllCompanies, fetchCompanies } from '../../slices/companies/companiesSlice';
import { PRICE_IDS } from '../../common/constants/priceId';
import { EMAILS } from '../../common/constants/emails';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { selectUser } from '../../slices/user/userSlice';

const PricingPage = () => {
  const token = localStorage.getItem('access_token');
  const currentUser = useAppSelector(selectUser);
  const { t } = useTranslation();
  const [pendingStatus, setPendingStatus] = useState(true);
  const [stripeProducts, setStripeProducts] = useState([]);
  const [errorTextArray, setErrorTextArray] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [redirectToCompanyDetails, setRedirectToCompanyDetails] = useState(false);
  const dialogTitle = t('contactUs.header');
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectAllCompanies)[0];
  const priceIdEnv =
    process.env.ENV_TYPE && process.env.ENV_TYPE === 'PROD'
      ? PRICE_IDS.PRICE_ID_PROD
      : PRICE_IDS.PRICE_ID_TEST;

  const DialogContent = () => (
    <p>
      {t('messages.getInTouch')} <a href={'mailto:' + EMAILS.SALES_EMAIL}>{EMAILS.SALES_EMAIL}</a>.
    </p>
  );

  const getStripeProducts = () => {
    NWClient.list(token, 'stripe/product')
      .then((res) => {
        setStripeProducts(res);
      })
      .catch((error) => {
        Helpers.handleRequestError(error.response, setErrorTextArray, setPendingStatus);
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };

  useEffect(() => {
    if (!company) {
      dispatch(fetchCompanies(token))
        .unwrap()
        .then((res) => {
          if (Array.isArray(res) && res.length === 0) {
            setRedirectToCompanyDetails(true);
          }
          getStripeProducts();
        })
        .catch((error) => {
          Helpers.handleRequestError(error.response, setErrorTextArray, setPendingStatus);
        });
    } else {
      getStripeProducts();
    }
  }, [dispatch, token, company]);

  return (
    <React.Fragment>
      <h1 className='mw-1100 text-center'>{t('pricing.header')}</h1>
      <div className='price-options-container'>
        <PriceOption
          id='freeAccountOption'
          name={t('pricingOptionNames.free')}
          humanReadablePrice={t('humanReadablePrice.free')}
          billingInfo={t('billingInfo.free')}
          customBillingInformation
          bestValueOption={false}
          customCSSClass={'free-account'}
          customControl={
            <Button disabled variant='contained' size='medium'>
              {t('subscriptionPlanControls.free')}
            </Button>
          }
        />
        {stripeProducts.map((el) => {
          if (el.active) {
            return el.prices.map((item: Price) => {
              if (item.active && item.id === priceIdEnv) {
                /*const isBestValue = el.prices.length === 1 || el.prices.every((price) => {
                                            return price.unitAmount > item.unitAmount
                                          });*/
                return (
                  <PriceOption
                    key={item.id}
                    productId={el.djstripeId}
                    id={item.id}
                    clientEmail={currentUser.email}
                    clientUUID={currentUser.user_uuid}
                    name={el.name}
                    humanReadablePrice={item.humanReadablePrice}
                    customBillingInformation
                    billingInfo={t('billingInfo.premium')}
                    /*billingInfo={item.recurring ? `every ${item.recurring.interval}` : 'once'}*/
                    bestValueOption={false}
                    redirectToCompanyDetails={redirectToCompanyDetails}
                  />
                ); //false for now
              }
            });
          }
        })}
        <PriceOption
          id='enterpriseAccountOption'
          name={t('pricingOptionNames.enterprise')}
          humanReadablePrice={t('humanReadablePrice.enterprise')}
          billingInfo={t('billingInfo.enterprise')}
          customBillingInformation
          bestValueOption={false}
          customCSSClass={'enterprise-account'}
          customControl={
            <Button variant='contained' size='medium' onClick={() => setShowDialog(true)}>
              {t('subscriptionPlanControls.enterprise')}
            </Button>
          }
        />
      </div>
      <div className='mt-5 text-center'>
        {t('contactUs.message')}{' '}
        <a href={'mailto:' + EMAILS.SUPPORT_EMAIL}>{EMAILS.SUPPORT_EMAIL}</a>.
      </div>
      <div className='mt-3 mb-2 d-flex justify-content-end'>
        {stripeProducts.length > 0 && (
          <Button
            variant='contained'
            size='medium'
            component={RouterLink}
            to={
              redirectToCompanyDetails
                ? ROUTES.COMPANY_CREATE + `?fromPricingPage=true&priceId=${priceIdEnv}`
                : ROUTES.PRODUCT_PLAN_CONFIRMATION + `?priceId=${priceIdEnv}`
            }
          >
            {t('buttons.percents100')}
          </Button>
        )}
      </div>
      <FormErrorText textCentered={true} errorTextArray={errorTextArray} />
      {showDialog && (
        <DialogItem
          title={dialogTitle}
          isErrorMessage={false}
          open={showDialog}
          handleClose={() => setShowDialog(false)}
        >
          <DialogContent />
        </DialogItem>
      )}
      {pendingStatus && <ProgressOverlay />}
    </React.Fragment>
  );
};
export default PricingPage;

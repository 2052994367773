import { BLACK_COLOR, BRAND_COLOR, ORANGE_COLOR, WHITE_COLOR } from './constants/colors';

export const getColour = (val: string) => {
  return val && val.length > 0 ? '#' + val : 'inherit';
};

export const getResourceLink = (resource: string) => {
  return `https://business-kitz-resources.s3.ap-southeast-2.amazonaws.com${resource}`;
};

export const getSvgIconColorByCategory = (name: string) => {
  switch (name) {
    case 'business':
      return ORANGE_COLOR;
    case 'tenders':
      return ORANGE_COLOR;
    default:
      return BRAND_COLOR;
  }
};

export const getSvgIconColorHeaderByCategory = (name: string) => {
  switch (name) {
    case 'employment':
      return WHITE_COLOR;
    case 'business':
      return BLACK_COLOR;
    default:
      return BRAND_COLOR;
  }
};

export const ALLOWED_IFRAME_URLS = {
  DEVELOPMENT_URL_DOCUMENTS: 'http://localhost:3000/documents',
  DEVELOPMENT_URL_DOCUMENTS_BASIC: 'http://localhost:3000',
  TEST_URL_DOCUMENTS: 'https://uat-signature.businesskitz.com.au/documents',
  /*'https://signature.businesskitz.com.au/documents',*/
  TEST_URL_DOCUMENTS_BASIC: 'https://uat-signature.businesskitz.com.au',
  /*'https://signature.businesskitz.com.au',*/
  PROD_URL_DOCUMENTS: 'https://signature.businesskitz.com.au/documents',
  PROD_URL_DOCUMENTS_BASIC: 'https://signature.businesskitz.com.au',
};

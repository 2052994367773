import React from 'react';
import { useTranslation } from 'react-i18next';

export interface FeatureSlideProps {
  imageSrc?: string;
  slideTitle: string;
  descriptionText?: string;
  withReleasedLabel?: boolean;
}

const FeatureSlide = (props: FeatureSlideProps) => {
  const { t } = useTranslation();
  return (
    <div className='features-slide'>
      <h3 className='slide-title'>{props.slideTitle}</h3>
      {props.descriptionText ? <p className='description-text'>{props.descriptionText}</p> : ''}
      {props.withReleasedLabel && (
        <div className='label-released'>
          <span>{t('messages.released')}</span>
        </div>
      )}
    </div>
  );
};

export default FeatureSlide;

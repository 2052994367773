import React from 'react';
import { useTranslation } from 'react-i18next';

interface PasswordRulesBlockProps {
  id: string;
  passwordLengthEnough: boolean;
  hasLowerUpperCase: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  passwordMatch: boolean;
}

const PasswordRulesBlock = (props: PasswordRulesBlockProps) => {
  const { t } = useTranslation();
  return (
    <div className='password-rules' id={props.id}>
      <ul>
        <li className={props.passwordLengthEnough ? 'fulfilled' : ''}>
          {t('passwordRules.atLeast12characters')}
        </li>
        <li className={props.hasLowerUpperCase ? 'fulfilled' : ''}>
          {t('passwordRules.uppercaseLowercase')}
        </li>
        <li className={props.hasNumber ? 'fulfilled' : ''}>
          {t('passwordRules.atLeastOneNumber')}
        </li>
        <li className={props.hasSpecialCharacter ? 'fulfilled' : ''}>
          {t('passwordRules.atLeastOneSpecialCharacter')}
        </li>
        <li className={props.passwordMatch ? 'fulfilled' : ''}>
          {t('passwordRules.passwordsMustMatch')}
        </li>
      </ul>
    </div>
  );
};

export default PasswordRulesBlock;

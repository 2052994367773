import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLinkItem } from '../templates/components/DashboardLinks/DashboardLinkItem';
import { ROUTES } from '../../common/constants/routes';

const DocumentLibraryGeneralPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className='text-center mb-4 fs-2'>{t('pages.templateCategories')}</h1>
      <div className='d-flex flex-wrap justify-content-center align-items-start'>
        <div className='d-flex flex-wrap justify-content-center'>
          <ul className='dashboard-links list-unstyled d-flex flex-wrap justify-content-center'>
            <DashboardLinkItem
              imageSrc='assets/svg/australia-logo.svg'
              linkText={t('AustralianTemplates')}
              route={`${ROUTES.DOCUMENT_LIBRARY}/au`}
              /*svgClassName='vault-icon'*/
            />
            <DashboardLinkItem
              imageSrc='assets/svg/australia-logo.svg'
              linkText={t('AustralianTemplatesDocx')}
              route={ROUTES.CATEGORY_LIST}
              /*svgClassName='vault-icon'*/
            />
            <DashboardLinkItem
              imageSrc='assets/svg/uk-logo.svg'
              linkText={t('UKTemplates')}
              route={`${ROUTES.DOCUMENT_LIBRARY}/uk`}
              /*svgClassName='vault-icon'*/
            />
            <DashboardLinkItem
              route={`${ROUTES.DOCUMENT_LIBRARY}/company-templates`}
              imageSrc='assets/svg/generate-template-icon.svg'
              linkText={t('CompanyTemplates')}
              svgClassName='vault-icon'
            />
          </ul>
        </div>
      </div>
    </div>
  );
};
export default DocumentLibraryGeneralPage;

import React, { useMemo, useState } from 'react';
import TemplateDashboardSearchBar from '../../../templates/TemplateDashboardSearchBar';
import { Grid } from '@mui/material';
import { getResourceLink, getSvgIconColorByCategory } from '../../../../common/utils';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { selectAllCategories } from '../../../../slices/categories/categoriesSlice';
import { useTranslation } from 'react-i18next';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { SearchCategoriesContainer } from '../../../templates/components/SearchCategoriesContainer';

export const CategoriesHeader = () => {
  const { name } = useParams();
  const categories = useSelector(selectAllCategories);
  const { t } = useTranslation();
  const [loadImageError, setLoadImageError] = useState(false);

  const category = useMemo(() => {
    if (name && categories) {
      return categories.find(
        (category) => category.name.trim().toLowerCase() === name.toLowerCase()
      );
    }

    if (!name) {
      return {
        name: t('Templates'),
        icon: '/icons/Category/Category.svg',
      };
    }

    return {
      name: '',
      icon: '',
    };
  }, [categories, name, t]);

  const defaultSVGStyles = { fill: getSvgIconColorByCategory(category.name.toLowerCase()) };

  return (
    category && (
      <>
        <Grid container className='categories-header mb-4' spacing={{ xs: 4, sm: 0 }}>
          <Grid container xs={12} sm={6} item alignItems={'center'} direction={'row'}>
            {/*loadImageError ? (
              <FolderOutlinedIcon
                style={defaultSVGStyles}
                className='categories-header-icon default-icon'
              />
            ) : (
              <SVG
                className='categories-header-icon'
                color={getSvgIconColorByCategory(category.name.toLowerCase())}
                preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                description='category icon'
                src={getResourceLink(category.icon)}
                onError={() => setLoadImageError(true)}
                loader={
                  <FolderOutlinedIcon
                    style={defaultSVGStyles}
                    className='categories-header-icon default-icon'
                  />
                }
              />
            )*/}
            <h1 className='categories-header-title'>{category.name}</h1>
          </Grid>
          <Grid xs={12} sm={6} item>
            {name && (
              <TemplateDashboardSearchBar placeholder={t('searchInputText.shortPlaceholder')} />
            )}
            {!name && <SearchCategoriesContainer hideList={true} />}
          </Grid>
        </Grid>
      </>
    )
  );
};

import React from 'react';
import { Grid } from '@mui/material';
import { useGreetingUser } from '../templates/hooks/useWelcomingText';

const DashboardHeader = (): JSX.Element => {
  const { greeting, date } = useGreetingUser();
  return (
    <Grid className='dashboard-header' container direction='column' sx={{ mb: 4 }}>
      <h3 className='dashboard-header-date'>{date}</h3>
      <h1 className='dashboard-header-greeting'>{greeting}</h1>
    </Grid>
  );
};

export default DashboardHeader;

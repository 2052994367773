import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../common/constants/routes';
import Button from '@mui/material/Button';
import Brand from '../../../static/assets/svg/brand.svg';
import { primaryColor } from '../../common/theme/theme';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className='p-4'>
      <div className='text-center mb-4 svg-wrapper' style={{ color: primaryColor }}>
        <Brand />
      </div>
      <h1 className='mb-4 text-center'>{t('pageNotFoundHeader')}</h1>
      <p className='mb-4 text-center'>{t('pageNotFoundMessage')}</p>
      <div className='text-center'>
        <Button component={RouterLink} variant='contained' size='medium' to={ROUTES.INDEX}>
          {t('goHomeLink')}
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid, IconButton, InputLabel, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { REGEXP } from '../../../../../common/constants/regexp';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { FORM_ITEMS } from '../../../../../common/constants/form_items';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { toast } from 'react-toastify';
import { Employee } from '../../../../../models/employee.model';
import { Company } from '../../../../../models/company.model';
import { InviteStatus } from './InviteStatus';
import { INVITE_STATUS } from '../../../../../common/constants/statuses';
import { useTranslation } from 'react-i18next';

interface InviteEmployeeFormControlsProps {
  employee: Employee;
  company: Company;
  onSubmit: (e: React.MouseEvent<HTMLElement>, employee: Employee) => Promise<Employee>;
  onRemove: (e: React.MouseEvent<HTMLElement>, id: number) => void;
}

export const InviteEmployeeFormControls = ({
  employee,
  onSubmit,
  onRemove,
  company,
}: InviteEmployeeFormControlsProps) => {
  const [email, setEmail] = useState(employee.email);
  const [status, setStatus] = useState<INVITE_STATUS>(INVITE_STATUS.DEFAULT);

  const emailRegExp = REGEXP.EMAIL;

  const [emailValid, setEmailValid] = useState(false);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setEmailValid(emailRegExp.test(email));
  }, [email, emailRegExp]);

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    if (email && emailValid) {
      setStatus(INVITE_STATUS.LOADING);
      onSubmit(event, { email, company_id: company.id })
        .then(() => {
          toast.success(t('messages.inviteHasBeenSent'), { theme: 'colored' });
          setStatus(INVITE_STATUS.COMPLETED);
        })
        .catch((err) => {
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          if (err.response.status === 409) {
            setError(err?.response?.data);
          }
          setStatus(INVITE_STATUS.DEFAULT);
        });
    }
  };

  return (
    <ValidatorForm
      className='form'
      aria-errormessage={FORM_ITEMS.ERROR_CONTAINER_ID}
      onSubmit={(e: React.MouseEvent<HTMLElement>) => handleSubmit(e)}
    >
      <Grid
        container
        flexDirection='row'
        item
        spacing={{ md: 2 }}
        justifyContent='space-between'
        className='form-controls'
      >
        <Grid item xs={10}>
          <InputLabel className='form-label'>Email</InputLabel>
          <TextValidator
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            name='email'
            inputProps={{ title: t('inputs.employeeEmailTitle') }}
            value={email}
            disabled={status === INVITE_STATUS.COMPLETED || status === INVITE_STATUS.LOADING}
            className='form-control'
            validators={['required', 'isEmail']}
            errorMessages={[t('messages.fieldRequired'), t('messages.emailNotValid')]}
          />
        </Grid>
        <Grid container className='form-actions' alignItems='center' item xs={12} md={2}>
          <InviteStatus status={status}>
            <IconButton
              type='submit'
              disabled={!emailValid || status === INVITE_STATUS.COMPLETED}
              role='button'
            >
              <MailOutlineIcon />
            </IconButton>
          </InviteStatus>
          <IconButton
            role='button'
            disabled={status === INVITE_STATUS.LOADING}
            onClick={(event) => onRemove(event, employee.id)}
          >
            <CloseIcon />
          </IconButton>
          {error ? (
            <Tooltip classes={{ popper: 'error-tooltip' }} title={error}>
              <ErrorIcon className='error-icon' />
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

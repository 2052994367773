import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import { BLACK_COLOR, LIGHT_GREY } from '../../common/constants/colors';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { CompanyMember } from '../../models/member.model';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { EmployeesCreateModal } from '../company-create/components/modal/EmployeesCreateModal';
import Button from '@mui/material/Button';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';

const EmployeeProfiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpenEmployeesCreation, setIsOpenEmployeesCreation] = useState(false);
  const handleOpen = () => setIsOpenEmployeesCreation(true);
  const handleClose = () => {
    setIsOpenEmployeesCreation(false);
  };
  const company = useAppSelector(selectAllCompanies)[0];
  const employees: CompanyMember[] = company?.members || [];
  const employeesFiltered = employees.filter((el) =>
    el.groups.every((item) => item.name !== 'Company admin')
  );
  const employeesSorted = employeesFiltered.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );

  return (
    <React.Fragment>
      <div className='header-wrapper employees-page-header d-md-flex d-lg-flex justify-content-md-between justify-content-lg-between align-items-md-start align-items-lg-start mb-3'>
        <h1 className='mb-2 fs-2 mb-md-0 mb-lg-0'>{t('employeeProfiles.header')}</h1>
        <div className='controls-wrapper text-center text-md-left text-lg-left'>
          <Button type='button' variant='outlined' size='large' onClick={handleOpen}>
            <PersonAddAltOutlinedIcon sx={{ width: 30, height: 30 }} color='primary' />
            <span>{t('buttons.addAnEmployee')}</span>
          </Button>
        </div>
      </div>
      <div className='invite-employees-items employees-list d-flex align-items-start justify-content-center justify-content-md-start flex-wrap'>
        {employeesSorted.length > 0 ? (
          employeesSorted.map((employee) => (
            <div
              role='button'
              tabIndex={-1}
              key={employee.email}
              className='invite-employees-item'
              onClick={() => navigate(ROUTES.EMPLOYEE_PROFILES + '/' + employee.id)}
              onKeyUp={(e) => console.log(e)}
            >
              <div className='invite-employees-item-avatar d-flex justify-content-center'>
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    fontSize: '2rem',
                    backgroundColor: LIGHT_GREY,
                    color: BLACK_COLOR,
                  }}
                >{`${employee?.first_name?.[0]}${employee.last_name?.[0]}`}</Avatar>
              </div>
              <div className='invite-employees-item-name'>
                {employee.first_name} {employee.last_name}
              </div>
              <div className='invite-employees-item-contacts'>{employee.email}</div>
            </div>
          ))
        ) : (
          <div className='no-employees-message text-center mt-5 ps-5 pe-5'>
            <p className='mb-5 fs-5'>{t('messages.noEmployeesLine1')}</p>
            <p className='fs-5 fst-italic'>{t('messages.noEmployeesLine2')}</p>
          </div>
        )}
      </div>
      <EmployeesCreateModal open={isOpenEmployeesCreation} onClose={handleClose} />
    </React.Fragment>
  );
};

export default EmployeeProfiles;

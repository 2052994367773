import React, { PropsWithChildren, useMemo } from 'react';
import { selectUserFetchStatus, selectUserRoleByGroupName } from '../../slices/user/userSlice';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../constants/routes';

interface RolesGateProps {
  group: string;
}

export const RolesGate = ({ group, children }: PropsWithChildren<RolesGateProps>) => {
  const userGroup = useSelector(selectUserRoleByGroupName(group));

  const isHasGroup = useMemo(() => {
    return Boolean(userGroup);
  }, [userGroup]);

  if (isHasGroup) {
    return <>{children}</>;
  }

  return null;
};

export const RolesGateRedirect = ({ group, children }: PropsWithChildren<RolesGateProps>) => {
  const userGroup = useSelector(selectUserRoleByGroupName(group));
  const status = useSelector(selectUserFetchStatus);

  const isHasGroup = useMemo(() => {
    return Boolean(userGroup);
  }, [userGroup]);

  if (status === 'loading' || !status) {
    return null;
  }

  if (isHasGroup) {
    return <>{children}</>;
  }

  return <Navigate to={ROUTES.INDEX} replace />;
};

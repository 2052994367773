import React, { useState } from 'react';
import { ROUTES } from '../../../../common/constants/routes';
import { useTranslation } from 'react-i18next';
import { DashboardLinkItem } from './DashboardLinkItem';
import { DashboardLinksProps } from './types';

const DashboardLinks = ({ company }: DashboardLinksProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ul className='dashboard-links list-unstyled d-flex flex-wrap justify-content-center'>
        <DashboardLinkItem
          route={ROUTES.DOCUMENT_LIBRARY}
          imageSrc='assets/svg/menu/business-dashboard-icon.svg'
          linkText={t('pages.templateCategories')}
        />
        <DashboardLinkItem
          route={ROUTES.DOCUMENT_VAULT}
          imageSrc='assets/svg/menu/vault-icon.svg'
          linkText={t('pages.documentVault')}
          svgClassName='vault-icon'
        />
        <DashboardLinkItem
          route={ROUTES.DOCUMENTS}
          imageSrc='assets/svg/menu/documents-signing.svg'
          linkText={t('pages.signing')}
          svgClassName='signing-icon'
        />
        <DashboardLinkItem
          route={
            company
              ? ROUTES.EMPLOYEE_PROFILES
              : ROUTES.COMPANY_CREATE + '?backUrl=' + ROUTES.EMPLOYEE_PROFILES
          }
          imageSrc='assets/svg/menu/employment-icon.svg'
          linkText={t('pages.employeeProfiles')}
        />
        <DashboardLinkItem
          route={
            company
              ? ROUTES.CUSTOMER_PROFILES
              : ROUTES.COMPANY_CREATE + '?backUrl=' + ROUTES.CUSTOMER_PROFILES
          }
          imageSrc='assets/svg/customers.svg'
          linkText={t('pages.customerProfiles')}
          svgClassName='customers-icon'
        />
        <DashboardLinkItem
          route={
            company
              ? ROUTES.SUPPLIER_PROFILES
              : ROUTES.COMPANY_CREATE + '?backUrl=' + ROUTES.SUPPLIER_PROFILES
          }
          imageSrc='assets/svg/menu/business-icon.svg'
          linkText={t('pages.supplierProfiles')}
          svgClassName='suppliers-icon'
        />
      </ul>
    </>
  );
};

export { DashboardLinks };

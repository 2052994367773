import { BLACK_COLOR, BRAND_COLOR, ORANGE_COLOR, PINK_COLOR, WHITE_COLOR } from './colors';
import { getResourceLink } from '../utils';

export const CATEGORIES = [
  {
    key: 'Employment',
    name: 'Employment',
    icon: getResourceLink('/icons/Employment/Employment.svg'),
    text_colour: WHITE_COLOR,
    background_colour: PINK_COLOR,
    link: '/categories/employment',
  },
  {
    key: 'Business',
    name: 'Business',
    icon: getResourceLink('/icons/Business/Business.svg'),
    text_colour: BLACK_COLOR,
    background_colour: ORANGE_COLOR,
    link: '/categories/business',
  },
  {
    key: 'viewAllCategories',
    //name: 'buttons.viewAllCategories',
    name: 'View all categories',
    icon: getResourceLink('/icons/Category/Category.svg'),
    text_colour: BLACK_COLOR,
    background_colour: WHITE_COLOR,
    borderColour: BRAND_COLOR,
    link: '/categories',
  },
];

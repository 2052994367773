import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { StyledTableCell } from '../TableCell';
import { useTranslation } from 'react-i18next';

export const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>{t('templatesTable.nameColumnLabel')}</StyledTableCell>
        <StyledTableCell align='right'>{t('templatesTable.actionColumnLabel')}</StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

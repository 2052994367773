import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { RootState } from '../../store';
import { User } from '../../models/user.model';

export const fetchUser = createAsyncThunk('user/fetchUser', async (token: string) => {
  const response = await NWClient.currentUser(token);
  return response;
});

export interface UserState {
  profile: User;
  status: string;
  error: string;
}

const userInitialState: User = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  groups: [],
  user_uuid: '',
};

const initialState: UserState = {
  profile: userInitialState,
  status: '',
  error: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.profile = userInitialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.profile = action.payload;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default userSlice.reducer;

export const selectUser = (state: RootState) => state.user.profile;
export const selectUserFetchStatus = (state: RootState) => state.user.status;
export const selectUserFetchError = (state: RootState) => state.user.error;
export const selectUserRoleByGroupName = (groupName: string) => (state: RootState) =>
  state.user.profile.groups.find((group) => group.name === groupName);

export const { logout } = userSlice.actions;

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import React from 'react';

export const ProgressOverlay = (props: CircularProgressProps) => {
  const { size, color, className } = props;
  return (
    <div className={`progress-wrapper ${className}`}>
      {/*<CircularProgress size={size || 60} color={color || 'primary'} />*/}
      <div className='pulse'>
        <svg height='306' width='306'>
          <defs>
            <linearGradient id='gradient' x1='95%' y1='55%' x2='0%' y2='100%'>
              <stop offset='0%' stopColor='#6414DB' stopOpacity='0' />
              <stop offset='50%' stopColor='#6414DB' />
            </linearGradient>
          </defs>
          <circle className='still' cx='150' cy='150' r='144' />
          <circle cx='150' cy='150' r='144' />
          <circle cx='150' cy='150' r='144' />
          {/*<circle cx="150" cy="150" r="144"/>*/}
        </svg>
      </div>
    </div>
  );
};

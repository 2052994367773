import React, { PropsWithChildren } from 'react';
import { SelectField } from '../../common/nw-select';
import { Autocomplete, Grid, TextField, CircularProgress } from '@mui/material';
import { useSearchBar } from './hooks/useSearchBar';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import DialogItem from '../../common/dialog-item/DialogItem';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { EMAILS } from '../../common/constants/emails';

export type TemplateDashboardSearchBarProps = {
  //categories?: Category[];
  categories?: { name: string; id: number; className: string }[];
  placeholder?: string;
  filtered?: boolean;
};

const TemplateDashboardSearchBar = ({
  categories,
  placeholder,
  filtered,
}: PropsWithChildren<TemplateDashboardSearchBarProps>): JSX.Element => {
  const {
    options,
    selectOptions,
    handleSearchSelect,
    handleSearchInputChange,
    onOpenAutocomplete,
    onCloseAutocomplete,
    handleOptionClick,
    isDownloading,
    errorStatus,
    errorText,
    errorState,
    setErrorState,
  } = useSearchBar({ items: categories, filtered });

  const { t } = useTranslation();

  const errorMessageTitle = t('messages.errorOccurred');
  const NoPermissionMessage = () => {
    return (
      <>
        {t('messages.startSubscription')}&nbsp;<Link to={ROUTES.PRICING}>{t('links.here')}</Link>.
      </>
    );
  };
  const noPermissionTitle = t('messages.downloadMessageTitle');

  const tooltipText = t('messages.contactSupport') + ' ' + EMAILS.SUPPORT_EMAIL;
  const InfoTooltip = () => {
    return (
      <Tooltip title={tooltipText} placement='left'>
        <InfoIcon fontSize='small' color='secondary' />
      </Tooltip>
    );
  };
  return (
    <Grid container className='nw-search-field'>
      {filtered && (
        <Grid item xs={4} sm={2}>
          <SelectField
            onChange={handleSearchSelect}
            className='nw-search-field-select'
            defaultValue={'All'}
            optionProps={{ className: 'nw-search-field-select-option' }}
            options={selectOptions}
            disabled={isDownloading}
          />
        </Grid>
      )}
      <Grid container alignItems={'center'} item xs={filtered ? 8 : 12} sm={filtered ? 10 : 12}>
        <Grid item xs={11}>
          <Autocomplete
            freeSolo
            options={options}
            autoHighlight
            disablePortal
            disableClearable
            handleHomeEndKeys
            openOnFocus={false}
            disabled={isDownloading}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onInputChange={handleSearchInputChange}
            onOpen={onOpenAutocomplete}
            onClose={onCloseAutocomplete}
            onChange={handleOptionClick}
            renderOption={(props, option) => (
              <li {...props} key={option.id} className='nw-search-field-option'>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                type='text'
                variant='standard'
                placeholder={placeholder}
                label={placeholder}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  },
                }}
                InputLabelProps={{ style: { display: 'none' }, shrink: true }}
                className='nw-search-field-input'
              />
            )}
          />
        </Grid>
        <Grid container item xs={1} justifyContent='flex-end'>
          {!isDownloading ? <InfoTooltip /> : <CircularProgress size={15} />}
        </Grid>
      </Grid>
      <DialogItem
        isErrorMessage={errorState && errorStatus !== 403}
        open={errorState}
        title={errorStatus !== 403 ? errorMessageTitle : noPermissionTitle}
        text={errorText}
        handleClose={() => setErrorState(false)}
      >
        {errorStatus === 403 ? <NoPermissionMessage /> : null}
      </DialogItem>
    </Grid>
  );
};

export default TemplateDashboardSearchBar;

import React from 'react';
import { SearchCategoryItem } from './SearchCategoryItem';
import { Grid } from '@mui/material';
import { Category } from '../../../../models/category.model';

export type SearchCategoryListProps = {
  categories: Partial<Category>[];
};

export const SearchCategoryList = ({ categories }: SearchCategoryListProps) => (
  <Grid container wrap='wrap' className='dashboard-category-list' spacing={10}>
    {categories.map(({ name, text_colour, background_colour, icon, borderColour, link }) => (
      <SearchCategoryItem
        key={name}
        name={name}
        icon={icon}
        link={link}
        textColour={text_colour}
        backgroundColour={background_colour}
        borderColour={borderColour}
      />
    ))}
  </Grid>
);

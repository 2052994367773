import React, { Fragment } from 'react';
import { CategorySection } from '../CategorySection';
import { useCategoryList } from '../../hooks/useCategoryList';
import { CircularProgress } from '@mui/material';

export const Categories = () => {
  const { categories, isGroup } = useCategoryList();

  if (!categories) {
    return <CircularProgress />;
  }

  return (
    <>
      {categories.map(
        ({ id, name, icon, subcategories, background_colour, text_colour, parent, templates }) => {
          if (!(parent && subcategories.length > 0 && !isGroup) || isGroup) {
            return (
              <Fragment key={id}>
                <CategorySection
                  id={id}
                  key={id}
                  name={name}
                  icon={icon}
                  backgroundColour={background_colour}
                  textColour={text_colour}
                  subcategories={subcategories}
                  templates={templates}
                  isGroup={isGroup}
                />
              </Fragment>
            );
          }
        }
      )}
    </>
  );
};

import React from 'react';
import { Categories } from './components/Categories';

import { CategoriesHeader } from './components/CategoriesHeader';

const CategoriesPage = () => {
  return (
    <React.Fragment>
      <CategoriesHeader />
      <Categories />
    </React.Fragment>
  );
};
export default CategoriesPage;

import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { getResourceLink } from '../../../../common/utils';
import { ROUTES } from '../../../../common/constants/routes';

export type SubcategoryProps = {
  id: number;
  name: string;
  icon: string;
  label?: string;
  showOrder?: boolean;
  parentName?: string;
};

export const Subcategory = ({ name, icon, label, showOrder, parentName }: SubcategoryProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loadImageError, setLoadImageError] = useState(false);
  const { basic_category: basicCategoryName, name: parentCategoryName } = useParams();
  const defineRoute = () => {
    if (location.pathname === ROUTES.CATEGORY_LIST && parentName) {
      return `${ROUTES.CATEGORY_LIST}/${parentName.toLowerCase()}/${name}`;
    } else if (
      parentCategoryName.toLowerCase() === parentName.toLowerCase() &&
      !basicCategoryName
    ) {
      return `${ROUTES.CATEGORY_LIST}/${parentCategoryName}/${name}`;
    } else if (parentCategoryName && basicCategoryName && parentName) {
      return `${ROUTES.CATEGORY_LIST}/${basicCategoryName}/${parentCategoryName}/${name}`;
    } else {
      return `${ROUTES.CATEGORY_LIST}/${name}`;
    }
  };
  return (
    <Grid container item justifyContent='center' xs={6} md={3} xl={2}>
      <button
        className='subcategory-item'
        onClick={() => navigate(defineRoute())}
        aria-label={`${name} navigation button`}
      >
        {showOrder && Boolean(label) && <Box className='subcategory-item-label'>{label}</Box>}
        <Box className='subcategory-item-icon'>
          {loadImageError ? (
            <FolderOutlinedIcon className='default-icon' />
          ) : (
            <SVG
              src={getResourceLink(icon)}
              preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
              description='subcategory icon'
              onError={() => setLoadImageError(true)}
              loader={<FolderOutlinedIcon className='default-icon' />}
            />
          )}
        </Box>
        <Typography align='center' className='subcategory-item-name'>
          {name}
        </Typography>
      </button>
    </Grid>
  );
};

import React from 'react';
import StartPageTemplate from '../../common/start-page-template/StartPageTemplate';
import PasswordResetForm from './PasswordResetForm';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useTranslation } from 'react-i18next';
import FeaturesContentLogIn from '../login/FeaturesContentLogIn';

const PasswordReset = () => {
  const { t } = useTranslation();
  const FormContent = () => {
    return (
      <div className='user-form-container'>
        <h1>{t('passwordReset.header')}</h1>
        <PasswordResetForm />
      </div>
    );
  };
  const BrandContent = () => {
    return (
      <div className='features-container'>
        <h2>{t('brandContent.header')}</h2>
        <FeaturesContentLogIn />
      </div>
    );
  };
  const LinkToLogin = () => {
    return (
      <Button component={RouterLink} variant='contained' size='medium' to={ROUTES.LOGIN}>
        {t('buttons.logIn')}
      </Button>
    );
  };
  return (
    <StartPageTemplate
      brandContainerContent={<BrandContent />}
      secondaryContainerContent={<FormContent />}
      linkToPage={<LinkToLogin />}
    />
  );
};

export default PasswordReset;

import React from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';

import CustomerProfileForm from '../CustomerProfileForm';

export const CustomerDetailsTab = ({ currentTab }: TabProps) => {
  return (
    <TabPanel value={currentTab} index={0} prefix={'vertical'}>
      <>
        <CustomerProfileForm />
      </>
    </TabPanel>
  );
};

import React from 'react';
import SubscriptionModel from '../../../../../models/subscription.model';
import FormErrorText from '../../../../../common/form-error-text/form-error-text';
import { useTranslation } from 'react-i18next';
import { EMAILS } from '../../../../../common/constants/emails';

interface SubscriptionItemProps extends SubscriptionModel {
  chargeAmount: string;
  chargeCurrency: string;
  chargeDate: string;
}

const SubscriptionItem = (props: SubscriptionItemProps) => {
  const {
    startDate,
    currentPeriodStart,
    currentPeriodEnd,
    status,
    errorTextArray,
    discount,
    chargeAmount,
    chargeCurrency,
    chargeDate,
  } = props;
  const { t } = useTranslation();
  return (
    <div className='subscription-info'>
      <div className='info-row'>
        <div className='item-label'>{t('labels.startDate')}:</div>
        <div className='item-value'>{startDate}</div>
      </div>
      <div className='info-row'>
        <div className='item-label'>{t('labels.currentPeriodStart')}:</div>
        <div className='item-value'>{currentPeriodStart}</div>
      </div>
      <div className='info-row'>
        <div className='item-label'>{t('labels.currentPeriodEnd')}:</div>
        <div className='item-value'>{currentPeriodEnd}</div>
      </div>
      <div className='info-row'>
        <div className='item-label'>{t('labels.nextPayment')}:</div>
        <div className='item-value'>
          {t('messages.yourNextPayment')} <span className='fw-bold'>{chargeAmount}</span>&nbsp;
          <span className='fw-bold text-uppercase'>{chargeCurrency}</span> {t('messages.deducted')}{' '}
          <span className='fw-bold'>{chargeDate}</span>.
        </div>
      </div>
      {discount && discount.coupon && (
        <>
          <div className='info-row'>
            <div className='item-label'>{t('labels.termsOfSubscription')}:</div>
            <div className='item-value'>{discount.coupon.duration_in_months}</div>
          </div>
        </>
      )}
      <div className='info-row'>
        <div className='item-label'>{t('labels.status')}:</div>
        <div className='item-value'>{status}</div>
      </div>
      <div className='info-row'>
        <p className='disclaimer-text'>
          {t('messages.contact.subscriptionTab')}
          <a href={'mailto:' + EMAILS.SUPPORT_EMAIL}>{EMAILS.SUPPORT_EMAIL}</a>.
        </p>
      </div>
      <FormErrorText errorTextArray={errorTextArray} />
    </div>
  );
};

export default SubscriptionItem;

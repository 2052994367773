import React, { useState } from 'react';
import ProductPlanConfirmation from './ProductPlanConfirmation';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const PlanConfirmation = () => {
  const [stripePromise] = useState(() => loadStripe(process.env.STRIPE_PUBLISHABLE_KEY));
  /*const options = {
    // passing the client secret obtained from the server
    clientSecret: 'pi_1Dse9l2eZvKYlo2C8PbRtJDk_secret_luryLyA5XDHgErXBIRF99BdWW',
  };*/
  return (
    <Elements stripe={stripePromise}>
      <ProductPlanConfirmation />
    </Elements>
  );
};

export default PlanConfirmation;

import React from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import SVG from 'react-inlinesvg';
import { BRAND_COLOR } from '../../../../common/constants/colors';
import { selectUser } from '../../../../slices/user/userSlice';
import { useSelector } from 'react-redux';
import { getResourceLink } from '../../../../common/utils';
import { useTranslation } from 'react-i18next';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants/routes';

export const EmployeeDashboardPanel = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Grid container item>
      <Grid container item className='dashboard-panel' xs={12}>
        <Grid container item className='dashboard-panel-container'>
          <Grid container item className='dashboard-panel-header' alignItems='center'>
            <SVG
              color={BRAND_COLOR}
              preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
              src={getResourceLink('/icons/Suitcase.svg')}
            />
            <span>Personal information</span>
          </Grid>
          <Grid className='dashboard-panel-field'>
            <div className='dashboard-panel-name'>{t('labels.firstName')}</div>
            <div className='dashboard-panel-value'>{user.first_name}</div>
          </Grid>
          <Grid className='dashboard-panel-field'>
            <div className='dashboard-panel-name'>{t('labels.lastName')}</div>
            <div className='dashboard-panel-value'>{user.last_name}</div>
          </Grid>
          <Grid className='dashboard-panel-field'>
            <div className='dashboard-panel-name'>{t('labels.email')}</div>
            <div className='dashboard-panel-value'>{user.email}</div>
          </Grid>
        </Grid>
      </Grid>
      {user?.details?.job_title ||
        user?.details?.basis_of_work ||
        user?.details?.position_description ||
        (user?.details?.employment_type && (
          <Grid container item className='dashboard-panel' xs={12} md={6}>
            <Grid container item className='dashboard-panel-container' flexDirection='column'>
              <Grid container item className='dashboard-panel-header' alignItems='center'>
                <SVG
                  color={BRAND_COLOR}
                  preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={getResourceLink('/icons/Employment/Employment.svg')}
                />
                <span>{t('labels.employmentDetails')}</span>
              </Grid>
              <Grid container item>
                <Grid container item xs={12} md={6} flexDirection='column'>
                  {user?.details?.job_title && (
                    <Grid className='dashboard-panel-field'>
                      <div className='dashboard-panel-name'>{t('labels.jobTitle')}</div>
                      <div className='dashboard-panel-value'>{user?.details?.job_title}</div>
                    </Grid>
                  )}
                  {user?.details?.basis_of_work && (
                    <Grid className='dashboard-panel-field'>
                      <div className='dashboard-panel-name'>{t('labels.basicOfWork')}</div>
                      <div className='dashboard-panel-value'>{user?.details?.basis_of_work}</div>
                    </Grid>
                  )}
                  {user?.details?.position_description && (
                    <Grid className='dashboard-panel-field'>
                      <div className='dashboard-panel-name'>{t('labels.positionDescription')}</div>
                      <div className='dashboard-panel-value'>
                        {user?.details?.position_description}
                      </div>
                    </Grid>
                  )}
                </Grid>
                {user?.details?.employment_type && (
                  <Grid container item xs={12} md={6} flexDirection='column'>
                    <Grid className='dashboard-panel-field'>
                      <div className='dashboard-panel-name'>{t('labels.employmentType')}</div>
                      <div className='dashboard-panel-value'>{user?.details?.employment_type}</div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      {user?.details?.account_name ||
        user?.details?.account_number ||
        (user?.details?.bsb && (
          <Grid container item className='dashboard-panel' xs={12} md={6}>
            <Grid container item className='dashboard-panel-container' flexDirection='column'>
              <Grid container item className='dashboard-panel-header' alignItems='center'>
                <SVG
                  color={BRAND_COLOR}
                  preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={getResourceLink('/icons/Payment.svg')}
                />
                <span>{t('labels.paymentDetails')}</span>
              </Grid>
              {user?.details?.account_name && (
                <Grid className='dashboard-panel-field'>
                  <div className='dashboard-panel-name'>{t('labels.accountDetails')}</div>
                  <div className='dashboard-panel-value'>{user?.details?.account_name}</div>
                </Grid>
              )}
              {user?.details?.account_number && (
                <Grid className='dashboard-panel-field'>
                  <div className='dashboard-panel-name'>{t('labels.accountNumber')}</div>
                  <div className='dashboard-panel-value'>{user?.details?.account_number}</div>
                </Grid>
              )}
              {user?.details?.bsb && (
                <Grid className='dashboard-panel-field'>
                  <div className='dashboard-panel-name'>{t('labels.bsb')}</div>
                  <div className='dashboard-panel-value'>{user?.details?.bsb}</div>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      <Grid container item alignItems='center' justifyContent='center' style={{ marginTop: 15 }}>
        <Tooltip title={t('buttons.editButtonTooltipText')} placement='top' arrow>
          <IconButton
            style={{ borderRadius: 30, padding: 20 }}
            onClick={() => navigate(ROUTES.EMPLOYEE_PROFILE_SETTINGS)}
          >
            <span style={{ marginRight: 10 }}>{t('buttons.editEmployeeProfile')}</span>
            <EditNoteOutlinedIcon sx={{ width: 30, height: 30 }} color='primary' />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

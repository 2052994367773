import React from 'react';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { DashboardLinkItemProps } from './types';
import { useSideNavbar } from '../../../../../common/nw-side-nav/hooks/useSideNavbar';

const DashboardLinkItem = ({
  route,
  imageSrc,
  linkText,
  svgClassName,
  onClick,
}: DashboardLinkItemProps) => {
  return route ? (
    <li>
      <Link
        className='tile-link dashboard-category-item d-flex align-items-center flex-column h-100 w-100'
        to={route}
      >
        <span className='svg-container d-flex justify-content-center align-items-center mb-3'>
          {imageSrc && (
            <SVG
              className={
                svgClassName ? 'dashboard-link-icon ' + svgClassName : 'dashboard-link-icon'
              }
              src={imageSrc}
            />
          )}
        </span>
        <span className='link-title'>{linkText}</span>
      </Link>
    </li>
  ) : (
    <li>
      <span
        role='button'
        tabIndex={-1}
        onClick={onClick}
        onKeyUp={() => {
          console.log('');
        }}
        className='tile-link dashboard-category-item d-flex align-items-center flex-column h-100 w-100'
      >
        <span className='svg-container d-flex justify-content-center align-items-center mb-3'>
          {imageSrc && (
            <SVG
              className={
                svgClassName ? 'dashboard-link-icon ' + svgClassName : 'dashboard-link-icon'
              }
              src={imageSrc}
            />
          )}
        </span>
        <span className='link-title'>{linkText}</span>
      </span>
    </li>
  );
};

export { DashboardLinkItem };

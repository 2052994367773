import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { EmployeesCreateModal } from '../../../company-create/components/modal/EmployeesCreateModal';
import { CompanyMember } from '../../../../models/member.model';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

export const InviteEmployeePanel = (props: {
  employees: CompanyMember[];
  renderOnlyIcon?: boolean;
}) => {
  const { t } = useTranslation();
  const { renderOnlyIcon } = props;
  const [isOpenEmployeesCreation, setIsOpenEmployeesCreation] = useState(false);
  const handleOpen = () => setIsOpenEmployeesCreation(true);
  const handleClose = () => {
    setIsOpenEmployeesCreation(false);
  };

  if (renderOnlyIcon) {
    return (
      <>
        <Tooltip title={t('buttons.addAnEmployee')} placement='top' arrow>
          <IconButton onClick={handleOpen}>
            <PersonAddAltOutlinedIcon sx={{ width: 30, height: 30 }} color='primary' />
          </IconButton>
        </Tooltip>
        <EmployeesCreateModal open={isOpenEmployeesCreation} onClose={handleClose} />
      </>
    );
  }

  return (
    <Grid container className='invite-employees-panel'>
      <Grid container item alignItems='center' justifyContent='center'>
        <IconButton style={{ borderRadius: 30, padding: 20 }} onClick={handleOpen}>
          <PersonAddAltOutlinedIcon sx={{ width: 30, height: 30 }} color='primary' />
          <span style={{ marginLeft: 10 }}>{t('buttons.addAnEmployee')}</span>
        </IconButton>
      </Grid>
      <EmployeesCreateModal open={isOpenEmployeesCreation} onClose={handleClose} />
    </Grid>
  );
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { Category } from '../../models/category.model';
import { RootState } from '../../store';

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (token: string) => {
    const response = await NWClient.list(token, 'category');
    return response;
  }
);

export interface CategoriesState {
  categoriesList: Category[];
  status: string;
  error: string;
}

const categoriesArray: Category[] = [];
const initialState: CategoriesState = {
  categoriesList: categoriesArray,
  status: 'idle',
  error: '',
};
const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (Array.isArray(action.payload)) {
          state.categoriesList = [...action.payload];
        }
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default categoriesSlice.reducer;

export const selectCategoriesStatus = (state: RootState) => state.categories.status;
export const selectAllCategories = (state: RootState) => state.categories.categoriesList;
export const selectCategoryById = (state: RootState, categoryId: number) =>
  state.categories.categoriesList.find((item: Category) => item.id === categoryId);
export const selectCategoryByName = (state: RootState, categoryName: string) =>
  state.categories.categoriesList.find(
    (item: Category) =>
      item.name.trim() === categoryName.trim() ||
      item.name.trim().toLowerCase() === categoryName.trim()
  );

import { CSSObject, Drawer, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

const drawerWidth = 320;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create(['width', 'height'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.between('xs', 'md')]: {
    width: `100%`,

    '& .nw-side-nav-container': {
      padding: '1rem 0.75rem',
      overflowY: 'hidden',
    },
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['width', 'height'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,

  '& .nw-side-nav-container': {
    padding: '1.25rem 1rem',
  },

  [theme.breakpoints.between('xs', 'md')]: {
    width: `100%`,
    height: 72,

    '& .nw-side-nav-header': {
      margin: 0,
    },

    '& .nw-side-nav-container': {
      overflowy: 'hidden',
      padding: '1rem 0.75rem',
    },
  },
});

export const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    height: 72,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .nw-side-link-button': {
        display: 'none',
      },
    }),
  })
);

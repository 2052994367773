import { styled, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.7)',
    padding: '0.75rem 0',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 'none',
    padding: '0.15rem 1rem',
  },
}));

import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';

type PackmanLoaderProps = { loading: boolean };

export const PackmanLoader = ({ loading }: PackmanLoaderProps) => (
  <div
    className='position-fixed w-100 vh-100 d-flex align-items-center justify-content-center'
    style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 3000, left: 0, top: 0 }}
  >
    <PacmanLoader loading={loading} color='#6414DB' />
  </div>
);

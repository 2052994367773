import React from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectAllDocuments } from '../../../../slices/documents/documentsSlice';
import { useTranslation } from 'react-i18next';
import { selectUser } from '../../../../slices/user/userSlice';
import { selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import { selectAllDocumentContracts } from '../../../../slices/document-contracts/documentContractsSlice';

export const DocumentConnectionsTab = ({ currentTab }: TabProps) => {
  const { documentId } = useParams();
  const document = useAppSelector(selectAllDocuments).find((el) => el.id === Number(documentId));
  const documentContract = useAppSelector(selectAllDocumentContracts).find(
    (el) => el.id === Number(documentId)
  );
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectUser);
  const company = useAppSelector(selectAllCompanies)[0];
  return (
    <TabPanel value={currentTab} index={3} prefix={'vertical'}>
      <>
        <div className='p-4'>
          <h2 className='mb-4'>{t('documentTabs.connections')}</h2>
        </div>
        <div className='d-flex p-4 pt-0'>
          <div className='col-3 pe-3 text-capitalize fw-bold'>{t('labels.category')}:</div>
          <div className='col-9 text-capitalize'>
            {document?.category || documentContract?.category}
          </div>
        </div>
        <div className='d-flex p-4 pt-0'>
          <div className='col-3 pe-3 text-capitalize fw-bold'>{t('labels.company')}:</div>
          <div className='col-9'>{company?.name}</div>
        </div>
        <div className='d-flex p-4 pt-0'>
          <div className='col-3 pe-3 text-capitalize fw-bold'>{t('labels.name')}:</div>
          <div className='col-9'>
            {currentUser.first_name
              ? currentUser.first_name + ' ' + currentUser.last_name
              : currentUser.last_name}
          </div>
        </div>
        <div className='d-flex p-4 pt-0'>
          <div className='col-3 pe-3 text-capitalize fw-bold'>{t('labels.email')}:</div>
          <div className='col-9'>{currentUser.email}</div>
        </div>
      </>
    </TabPanel>
  );
};

import React from 'react';
import Button from '@mui/material/Button';
import { PricingOptionModel } from '../../models/pricing-option.model';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const PricingOption = (props: PricingOptionModel) => {
  const {
    id,
    name,
    clientEmail,
    clientUUID,
    productId,
    humanReadablePrice,
    billingInfo,
    bestValueOption,
    customBillingInformation,
    customControl,
    customCSSClass,
    redirectToCompanyDetails,
  } = props;
  const { t } = useTranslation();
  const urlParams = `?productId=${productId}&priceId=${id}`;
  const paymentLinkParams = `?prefilled_email=${clientEmail}&client_reference_id=${clientUUID}`;
  const paramsFromPricingPage = `?fromPricingPage=true&productId=${productId}&priceId=${id}`;
  const classList = bestValueOption ? 'price-option-card best-value-option' : 'price-option-card';
  const classListWithCustomCheck = customCSSClass ? `${classList} ${customCSSClass}` : classList;

  return (
    <div className={classListWithCustomCheck}>
      {bestValueOption ? (
        <div className='option-label'>{t('pricingOptionNames.bestValue')}</div>
      ) : null}
      <h2>{name}</h2>
      <h3 className='month-user-relation'>{humanReadablePrice}</h3>
      <div className='billing-period'>
        {customBillingInformation
          ? parse(billingInfo)
          : t('billingInfo.billed') + ' ' + billingInfo}
      </div>
      {/*<p className="product-features">
        Unlimited access to all documents and templates
      </p>*/}
      <div className='buttons-row'>
        {customControl ? (
          customControl
        ) : (
          <Button
            component={RouterLink}
            variant='contained'
            size='large'
            to={
              redirectToCompanyDetails
                ? ROUTES.COMPANY_CREATE + paymentLinkParams + `&redirectToStripe=true`
                : (!process.env.ENV_TYPE || process.env.ENV_TYPE !== 'PROD'
                    ? ROUTES.STRIPE_PAYMENT_LINK
                    : ROUTES.STRIPE_PAYMENT_LINK_PROD) + paymentLinkParams
            }
          >
            {t('subscriptionPlanControls.paid')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PricingOption;

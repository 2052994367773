import { styled, TableRow } from '@mui/material';
import { BRAND_COLOR } from '../../../../../../common/constants/colors';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '&:hover': {
    '.MuiTableCell-root': {
      color: BRAND_COLOR,
      cursor: 'pointer',
    },
  },
}));

import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';

export type MenuButtonProps = {
  toggle: () => void;
  isOpen: boolean;
};

export const MenuButton = ({ toggle, isOpen }: MenuButtonProps) => (
  <IconButton
    disableRipple
    size='small'
    aria-label='navigation menu button'
    aria-controls='pages-navigation'
    onClick={toggle}
    aria-expanded={isOpen}
    className='menu-icon-button'
    color='inherit'
  >
    {isOpen && <ChevronLeftIcon color='primary' />}
    <MenuIcon color='primary' />
  </IconButton>
);

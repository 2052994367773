import React from 'react';
import StartPageTemplate from '../../common/start-page-template/StartPageTemplate';
import FeaturesContentLogIn from './FeaturesContentLogIn';
import LogInForm from './LogInForm';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useTranslation } from 'react-i18next';

const LogIn = () => {
  const { t } = useTranslation();
  const FormContent = () => {
    return (
      <div className='user-form-container'>
        <h1>{t('loginText.header')}</h1>
        <LogInForm />
      </div>
    );
  };
  const BrandContent = () => {
    return (
      <div className='features-container'>
        <FeaturesContentLogIn />
      </div>
    );
  };
  const LinkToSignUp = () => {
    return (
      <Button component={RouterLink} variant='contained' size='medium' to={ROUTES.SIGN_UP}>
        {t('buttons.signUp')}
      </Button>
    );
  };
  return (
    <StartPageTemplate
      brandContainerContent={<BrandContent />}
      secondaryContainerContent={<FormContent />}
      linkToPage={<LinkToSignUp />}
    />
  );
};

export default LogIn;

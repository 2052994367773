import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

export const useSideNavbar = (): { isOpen: boolean; toggle: () => void } => {
  const [isOpen, setIsOpen] = React.useState<boolean>(window.innerWidth > 991);
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    if (path !== ROUTES.INDEX) {
      setIsOpen(false);
    }
  }, [path]);
  const toggle = useCallback(() => {
    setIsOpen((status) => !status);
  }, []);

  return { isOpen, toggle };
};

import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { Helpers } from '../../../../common/helpers/helpers';
import CustomerIcon from '../../../../../static/assets/svg/menu/customers.svg';
import { useTranslation } from 'react-i18next';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { useParams } from 'react-router-dom';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';

interface SettingsMenuProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const SettingsMenu = ({ value, handleChange }: SettingsMenuProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  return (
    <div className='settings-menu'>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='navigation'
          orientation='vertical'
          role='navigation'
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          tabIndex={0}
        >
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            icon={
              <div className={'settings-menu-icon-container'}>
                <CustomerIcon />
              </div>
            }
            label={t('customerTabs.basicDetails')}
            {...Helpers.a11yProps(0, 'vertical')}
          />
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            disabled={!customerId}
            icon={
              <div className={'settings-menu-icon-container'}>
                <ContactsOutlinedIcon />
              </div>
            }
            label={t('customerTabs.contactDetails')}
            {...Helpers.a11yProps(1, 'vertical')}
          />
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            disabled={!customerId}
            icon={
              <div className={'settings-menu-icon-container'}>
                <NotesOutlinedIcon />
              </div>
            }
            label={t('customerTabs.notes')}
            {...Helpers.a11yProps(2, 'vertical')}
          />
        </Tabs>
      </div>
    </div>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { ALLOWED_IFRAME_URLS } from '../../common/constants/allowed_iframe_urls';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import DialogItem from '../../common/dialog-item/DialogItem';
import { useTranslation } from 'react-i18next';
import { fetchUserSigningInfo } from '../../slices/user-signing-info/userSigningInfoSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';

const DocumentsPage = () => {
  const token = localStorage.getItem('access_token');
  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const userSigningInfo = useAppSelector((state) => state.userSigningInfo.info)[0];
  const iframeRef = useRef();
  const { t } = useTranslation();
  const [pendingStatus, setPendingStatus] = useState(true);
  const [error, setError] = useState(false);
  const getFrameUrl = () => {
    switch (process.env.ENV_TYPE) {
      case 'DEV':
        return process.env.DOCS_URL
          ? process.env.DOCS_URL
          : ALLOWED_IFRAME_URLS.DEVELOPMENT_URL_DOCUMENTS;
      case 'TEST':
        return ALLOWED_IFRAME_URLS.TEST_URL_DOCUMENTS;
      case 'PROD':
        return ALLOWED_IFRAME_URLS.PROD_URL_DOCUMENTS;
      default:
        return null;
    }
  };

  const sendMessage = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    iframeRef.current.contentWindow.postMessage(
      {
        email: currentUser.email,
        password: userSigningInfo.user_password,
        address: getFrameUrl(),
      },
      '*'
    );
  };

  useEffect(() => {
    if (!userSigningInfo) {
      dispatch(fetchUserSigningInfo(token))
        .unwrap()
        .then((res) => {
          if (Array.isArray(res) && res.length === 0) {
            setError(true);
          }
        });
    }
  }, [userSigningInfo]);

  useEffect(() => {
    if (iframeRef && iframeRef.current && userSigningInfo && currentUser) {
      intervalRef.current = setInterval(() => sendMessage(), 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [iframeRef, userSigningInfo, currentUser]);

  return (
    <div>
      <iframe
        id='docsIframe'
        className='documents-iframe'
        ref={iframeRef}
        title='Documents'
        style={{
          border: 0,
          width: '100%',
          visibility: !pendingStatus ? 'visible' : 'hidden',
        }}
        src={getFrameUrl()}
        onLoad={() => setPendingStatus(false)}
      />
      {pendingStatus ? <ProgressOverlay /> : null}
      <DialogItem isErrorMessage={false} open={error} handleClose={() => setError(false)}>
        <div>
          {t('messages.pleaseContactUsGeneral')}{' '}
          <a href='mailto:info@businesskitz.com'>info@businesskitz.com</a>.
        </div>
      </DialogItem>
    </div>
  );
};

export default DocumentsPage;

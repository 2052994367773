import React from 'react';
import ProcessMessage from '../../common/process-message/ProcessMessage';
import { useTranslation } from 'react-i18next';

const EmailSent = () => {
  const { t } = useTranslation();
  const text = t('messages.emailSent');
  return <ProcessMessage text={text} messageType='emailSent' />;
};

export default EmailSent;
